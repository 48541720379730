<template>
  <div class="apk-updates">

    <b-modal :active.sync="showUploadJson">
      <div class="box flex-col">
        <div class="bold font-size-120">Upload APK JSON</div>
        <div class="flex-1 margin-top-20">
          <textarea v-model="jsonStr" class="input height-100"></textarea>
        </div>
        <div class="buttons margin-top-20 flex">
          <div class="flex-1"></div>
          <button class="button is-small is-success" :disabled="!jsonStr.length" @click="submitJson()">Submit JSON</button>
          <button class="button is-small" @click="showUploadJson = false">Cancel</button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="showChangeLogModal">
      <div class="box flex-col" v-if="selectedApk">
        <div class="bold font-size-120">Edit Change Log for {{ appId }} v{{ selectedApk.version }}</div>
        <div class="flex-1 margin-top-20">
          <textarea v-model="changeLogStr" class="input height-100"></textarea>
        </div>
        <div class="buttons margin-top-20 flex">
          <div class="flex-1"></div>
          <button class="button is-small is-success" @click="submitChangeLog()">Submit Change Log</button>
          <button class="button is-small" @click="selectedApk = null">Cancel</button>
        </div>
      </div>
    </b-modal>

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name">
          APK Updates Files >
          <span class="has-text-grey">{{ appId }}</span>
        </div>
        <div class="flex-1"></div>
        <div class="bold pulse font-size-170 text-ccc pad-leff-20" v-if="apks === null">LOADING</div>
        <div class="buttons">
          <button class="button" @click="dev = !dev">{{ dev ? 'HIDE' : 'SHOW' }} DEV APKS</button>
          <button class="button is-success" @click="showUploadJson = true">Submit APK JSON</button>
        </div>
      </div>
      <div class="bg-red text-ccc pad-5 bold" v-if="dev">SHOWING DEVELOPMENT APKS</div>
      <div class="flex-table-header">
        <div class="flex-table-cell col-version">Version</div>
        <div class="flex-table-cell col-change-log">Change Log</div>
        <div class="flex-table-cell col-file-name">File Name</div>
        <div class="flex-table-cell col-url">Url</div>
        <div class="flex-table-cell col-date">Date</div>
        <div class="flex-table-cell col-size">Size</div>
      </div>

      <div class="flex-table-body" v-if="apks && apks.length">
        <div class="flex-table-row" v-for="apk in apks" :key="apk._id">
          <div class="flex-table-cell col-version bold">{{ apk.version }}</div>
          <div class="flex-table-cell col-change-log pre pointer flex" @click="selectedApk = apk">
            <div class="flex-1" v-html="apk.changeLog"></div>
            <div class="pad-left">
              <fa icon="edit" />
            </div>
          </div>
          <div class="flex-table-cell col-file-name">{{ apk.file_name }}</div>
          <div class="flex-table-cell col-url"><a :href="apk.url" target="_blank">{{ apk.url }}</a></div>
          <div class="flex-table-cell col-date">{{ apk.timestamp | formatDateTime }}</div>
          <div class="flex-table-cell col-size">{{ apk.bytes_total | prettyFileSize }}</div>
        </div>
      </div>
      <div class="flex-table-body center pad-top-20 bold has-text-grey-light" v-else>
        <p>THERE ARE NO DATA FILES</p>
        <button class="button is-small margin-top" @click="showUploadJson = true">Submit APK JSON</button>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
      dev: false,
      apks: null,
      showUploadJson: false,
      showChangeLogModal: false,
      jsonStr: '',
      changeLogStr: '',
      selectedApk: null
    }
  },
  computed: {
    appId () { return this.$route.params.id },
    collection () { return this.dev ? 'apkUpdatesDev' : 'apkUpdates' }
  },
  watch: {
    appId () { this.fetchData() },
    selectedApk () {
      this.changeLogStr = this.selectedApk ? this.selectedApk.changeLog : ''
      this.showChangeLogModal = Boolean(this.selectedApk)
    },
    dev () { this.fetchData() }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.unsub('apks')
      if (!this.appId) return
      this.subs.apks = this.$fs.collection(this.collection)
        .where('appId', '==', this.appId)
        .orderBy('version', 'desc')
        .onSnapshot(ss => {
          this.apks = this.$ssToArr(ss)
        })
    },
    async submitJson () {
      console.log('submitJson')

      try {
        const apkInfo = JSON.parse(this.jsonStr)

        const errs = []
        for (const key of ['file_name', 'version', 'url', 'bytes_total', 'checksum']) {
          if (!apkInfo[key]) errs.push(key)
        }
        if (errs.length) throw new Error('No ' + errs.join(', '))

        if (this.apks && this.apks.length && apkInfo.version < this.apks[0].version) {
          this.$showError('Version number is not greater than current version!')
          return
        }

        const confirmed = await this.$confirm({
          type: 'is-info',
          title: `${this.appId} new APK version`,
          message: `
            <p>Are you sure you want to add a new APK version?</p>
            <p class="bold">${apkInfo.version}</p>`
        })
        if (!confirmed) return

        apkInfo.appId = this.appId
        apkInfo.timestamp = this.$moment().unix()
        await this.$fs.collection(this.collection)
          .doc(`${this.appId}_${apkInfo.version}`)
          .set(apkInfo)

        this.jsonStr = ''
        this.showUploadJson = false
      } catch (error) {
        this.$showError(error)
      }
    },
    async submitChangeLog () {
      try {
        await this.$fs.doc(`/${this.collection}/${this.appId}_${this.selectedApk.version}`)
          .update({ changeLog: this.changeLogStr.replace(/[\r\n\t]/g, '') })
        this.selectedApk = null
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>

<style lang="scss">
.apk-updates {
  .col-version { flex: none; width: 90px }
  .col-change-log { flex: 3; }
  .col-file { flex: 1; }
  .col-url { flex: 2; }
  .col-date { flex: none; width: 170px; }
  .col-size { flex: none; width: 100px; }
}
</style>
