const classes = {
  motorway: ['motorway'],
  motorway_link: ['motorway_link'],
  trunk: ['trunk'],
  primary: ['primary'],
  secondary: ['secondary'],
  tertiary: ['tertiary'],
  link: ['trunk_link', 'primary_link', 'secondary_link', 'tertiary_link'],
  pedestrian: ['pedestrian'],
  street: ['unclassified', 'residential', 'road', 'living_street', 'raceway'],
  construction: ['construction'],
  track: ['track'],
  service: ['service'],
  ferry: ['ferry'],
  path: ['steps', 'corridor', 'crossing', 'piste', 'mtb', 'hiking', 'cycleway', 'footway', 'path', 'bridleway'],
  major_rail: ['rail', 'light_rail', 'subway'],
  aerialway: ['cable_car', 'gondola', 'mixed_lift', 'chair_lift', 'drag_lift', 't-bar', 'j-bar', 'platter', 'rope_tow', 'zip_line', 'magic_carpet', 'canopy'],
  golf: ['hole']
}

const zoomLevels = [
  { z: 5, classes: ['motorway', 'trunk'] },
  { z: 6, classes: ['motorway', 'trunk', 'primary'] },
  { z: 8, classes: ['motorway', 'motorway_link', 'trunk', 'primary', 'secondary', 'major_rail'] },
  { z: 10, classes: ['motorway', 'motorway_link', 'trunk', 'primary', 'secondary', 'tertiary', 'major_rail'] },
  { z: 11, classes: ['motorway', 'motorway_link', 'trunk', 'primary', 'secondary', 'tertiary', 'major_rail', 'street', 'ferry'] },
  { z: 12, classes: ['motorway', 'motorway_link', 'trunk', 'primary', 'secondary', 'tertiary', 'major_rail', 'street', 'ferry', 'pedestrian', 'service', 'link', 'construction', 'aerialway'] },
  { z: 13, classes: ['motorway', 'motorway_link', 'trunk', 'primary', 'secondary', 'tertiary', 'major_rail', 'street', 'ferry', 'pedestrian', 'service', 'link', 'construction', 'aerialway', 'track'] },
  { z: 14, classes: ['motorway', 'motorway_link', 'trunk', 'primary', 'secondary', 'tertiary', 'link', 'pedestrian', 'street', 'construction', 'track', 'service', 'ferry', 'path', 'major_rail', 'aerialway', 'golf'] }
]

const keys = [
  'motorway',
  'trunk',
  'primary',
  'secondary',
  'tertiary',
  'unclassified',
  'residential',
  'motorway_link',
  'trunk_link',
  'primary_link',
  'secondary_link',
  'tertiary_link',
  'living_street',
  'service',
  'pedestrian',
  'track',
  'raceway',
  'road',
  'footway',
  'bridleway',
  'steps',
  'corridor',
  'path',
  'cycleway',
  'crossing',
  'piste',
  'mtb',
  'hiking'
]

function getClass (key) {
  for (const className in classes) {
    if (classes[className].includes(key)) return className
  }
  throw new Error(`invalid class for ${key}`)
}

function getMinZ (className) {
  for (const zoomLevel of zoomLevels) {
    if (zoomLevel.classes.includes(className)) return zoomLevel.z
  }
  throw new Error(`invaled min zoom level for ROAD class ${className}`)
}

const road = {
  name: 'road',
  type: 'line',
  styles: []
}

for (const name of keys) {
  const c = getClass(name)
  const minZ = getMinZ(c)
  road.styles.push({ name, minZ })
}

export { road }
