<template>
  <div class="sign-in">
    <div class="box">
      <form @submit.prevent="signIn">
        <b-field label="Email">
          <b-input v-model="email" />
        </b-field>
        <b-field label="Password">
          <b-input v-model="password" type="password" />
        </b-field>
        <b-message v-if="errMsg" type="is-warning">
          {{ errMsg }}
        </b-message>
        <div class="buttons">
          <button type="submit" class="button" :class="{'is-loading': isSigningIn}">
            Sign In
          </button>
        </div>
        <span class="font-size-80 pointer" @click="forgotPassword()">forgot password?</span>
      </form>
    </div>

  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
export default {
  name: 'SignIn',
  data () {
    return {
      email: '',
      password: '',
      errMsg: null,
      isSigningIn: false
    }
  },
  computed: {
    user () { return this.$store.getters.user }
  },
  watch: {
    user () { this.checkUser() }
  },
  created () {
    this.checkUser()
  },
  methods: {
    signIn () {
      if (this.isSigningIn) {
        return
      }
      this.errMsg = ''
      this.isSigningIn = true
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then(user => {
          console.log('Signed In!')
        })
        .catch(error => {
          this.isSigningIn = false
          this.errMsg = error.message
        })
    },
    checkUser () {
      if (this.user) {
        this.isSigningIn = false
      }
    },
    forgotPassword () {
      this.$dialog.prompt({
        title: 'Forgot Password',
        message: 'Enter your email address to reset you password',
        inputAttrs: {
          type: 'email',
          placeholder: 'Email address'
        },
        onConfirm: value => {
          firebase.auth().sendPasswordResetEmail(value)
            .then(() => this.$showAlert(`A password reset email has been sent to <b>${value}</b>`))
            .catch(err => this.$showError(err))
        }
      })
    }
  }
}
</script>

<style>
  .sign-in .box {
    width: 300px;
    margin: 10% auto 0 auto;
  }
</style>
