<template>
  <div class="activations height-100">

    <b-modal :active.sync="showAddModal">
      <div class="box flex-col">
        <div class="bold font-size-200">Add Activation</div>

        <div v-if="adding === 'loading'" class="flex-1 flex-middle has-text-grey-light">
          <fa icon="spinner" size="3x" pulse />
        </div>

        <div v-else-if="adding === null" class="flex-1 flex-middle">
          <div>
            <div class="pad">
              <button @click="adding = 'new-code'" class="button is-medium width-100">Generate Activation Code</button>
            </div>
            <div class="pad">
              <button @click="adding = 'ip'" class="button is-medium width-100">Accept From IP address</button>
            </div>
            <div class="pad" v-if="settings">
              <button @click="onToggleAllowAllVmsUnits()" class="button is-medium width-100">Turn {{ settings.allowAllVmsUnits ? 'OFF' : 'ON' }} Allow All VMS Units</button>
            </div>
            <div class="pad" v-if="settings">
              <button @click="onToggleAllowAll()" class="button is-medium width-100">Turn {{ settings.allowAll ? 'OFF' : 'ON' }} Allow All</button>
            </div>
          </div>
        </div>

        <!-- CODE -->
        <div v-else-if="adding === 'new-code'" class="flex-1 flex-middle">
          <div class="center">
            <div class="bold font-size-120">NEW ACTIVATION CODE</div>
            <div class="margin-bottom"><br />code name is optional</div>
            <b-input v-model="newName" size="is-large center" placeholder="CODE NAME" />
            <button @click="genreateCode()" class="button is-info margin-top">Generate Code</button>
          </div>
        </div>
        <div v-else-if="adding === 'code'" class="flex-1 flex-middle">
          <div class="center">
            <div>NEW ACTIVATION CODE</div>
            <div class="bold has-text-grey" style="font-size: 4em;">
              {{ newCode }}
            </div>
          </div>
        </div>

        <!-- IP -->
        <div v-else-if="adding === 'ip'" class="flex-1 flex-middle">
          <div class="center">
            <div class="bold font-size-120">NEW ACTIVATION IP ADDRESS</div>
            <div class="margin-bottom">The ip address activation will be active until the end of the day <br />{{ $moment().endOf('day').unix() | formatDateTime }}</div>
            <b-input v-model="ipAddress" size="is-large center" placeholder="IP ADDRESS" />
            <div>name is optional</div>
            <b-input v-model="newName" size="is-large center margin-top" placeholder="NAME" />
            <button @click="onSubmitIpAddress()" class="button is-info margin-top">Submit</button>
          </div>
        </div>

      </div>
    </b-modal>

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name flex-1">Activations</div>
        <div class="buttons">
          <button class="button" @click="$back()">Back</button>
        </div>
      </div>

      <div class="bold has-background-warning flex pad-5" v-if="settings && settings.allowAll">
        <div class="flex-1 font-size-120">ALLOW ALL IS TURNED ON</div>
        <div><button @click="onToggleAllowAll()" class="button is-small">TURN OFF</button></div>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-1">Status</div>
        <div class="flex-table-cell flex-1">Type</div>
        <div class="flex-table-cell flex-1">Value</div>
        <div class="flex-table-cell flex-2">Timestamp</div>
        <div class="flex-table-cell flex-2">User</div>
        <div class="flex-table-cell flex-0 w-70px center">
          <button @click="showAddModal = true" class="button is-small is-success"><fa icon="plus" /></button>
        </div>
      </div>
      <div v-if="items" class="flex-table-body">
        <div v-for="item in items" :key="item._id" class="flex-table-row">
          <div :class="item.status" class="flex-table-cell flex-1 bold uc">{{ item.status }}</div>
          <div class="flex-table-cell flex-1">
            <div class="bold uc">{{ item.type }}</div>
            <div v-if="item.name" class="font-size-80 has-text-grey">
              <b>{{ item.name }}</b>
            </div>
          </div>
          <div class="flex-table-cell flex-1">
            <template v-if="item.type === 'ip'">
              <ip-address :ip="item.value">{{ item.value }}</ip-address>
            </template>
            <template v-else>{{ item.value }}</template>
            <div class="font-size-80 has-text-grey" v-if="item.count !== undefined">
              <b>COUNT:</b> {{ item.count || 0 }}
            </div>
          </div>
          <div class="flex-table-cell flex-2">
            <div>{{ item.timestamp | formatDateTime }}</div>
            <div class="font-size-80 has-text-grey">
              <div v-if="item.type === 'code'"><b>USED:</b> {{ item.activationTimestamp | formatDateTime('-') }}</div>
              <div v-else-if="item.type === 'ip'"><b>EXPIRES:</b> {{ item.expires | formatDateTime }}</div>
            </div>
          </div>
          <div class="flex-table-cell flex-2">{{ item.user }}</div>
          <div class="flex-table-cell flex-0 w-70px center">
            <template v-if="cancelling === item._id">
              <fa icon="spinner" class="has-text-grey-light" pulse />
            </template>
            <template v-else-if="item.status === 'active'">
              <button @click="onCancel(item)" class="button is-small">
                <fa icon="ban" />
              </button>
            </template>
            <template v-else-if="item.status === 'cancelled'">
              <button @click="onReactivate(item)" class="button is-small">
                <fa icon="check-circle" />
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IpAddress from '@/components/IpAddress'
export default {
  components: { IpAddress },
  data () {
    return {
      showAddModal: false,
      settings: null,
      items: null,
      adding: null,
      newName: '',
      newCode: '',
      cancelling: null,
      ipAddress: ''
    }
  },
  computed: {
    user () { return this.$store.getters.user }
  },
  watch: {
    showAddModal () { this.adding = null }
  },
  created () {
    this.subs.settings = this.$fs.doc('/auth/settings')
      .onSnapshot(ss => {
        this.settings = this.$ssToObj(ss)
      })
    this.subs.activations = this.$fs.collection('activations')
      .orderBy('timestamp', 'desc')
      .onSnapshot(ss => {
        const timestamp = this.$moment().unix()
        this.items = this.$ssToArr(ss).map(item => {
          if (item.type === 'ip' && item.status === 'active' && timestamp > item.expires) {
            item.status = 'expired'
          }
          return item
        })
      })
  },
  methods: {
    async getActiveIpActivation (ip) {
      const timestamp = this.$moment().unix()
      const ss = await this.$fs.collection('activations')
        .where('type', '==', 'ip')
        .where('status', '==', 'active')
        .where('value', '==', ip)
        .where('expires', '>', timestamp)
        .get()
      return this.$ssToObj(ss)
    },
    async onSubmitIpAddress () {
      this.adding = 'loading'
      try {
        const m = this.$moment()
        const ip = this.ipAddress.trim()
        if (await this.getActiveIpActivation(ip)) {
          throw new Error(`IP activation for ${ip} already active`)
        }
        await this.$fs.collection('activations')
          .add({
            type: 'ip',
            name: this.newName.trim(),
            value: ip,
            status: 'active',
            user: this.user.email,
            timestamp: m.unix(),
            expires: m.endOf('day').unix(),
            count: 0,
            date: new Date()
          })
        this.newName = ''
        this.ipAddress = ''
        this.showAddModal = false
        this.adding = null
      } catch (error) {
        this.$showError(error)
        this.adding = 'ip'
      }
    },
    async onToggleAllowAllVmsUnits () {
      const confirmed = await this.$confirm({
        title: `Turn ${this.settings.allowAllVmsUnits ? 'OFF' : 'ON'} Allow All VMS Units`,
        type: 'is-warning',
        message: `Are you sure you want to turn <b>${this.settings.allowAllVmsUnits ? 'OFF' : 'ON'}</b> Allow All VMS Units`
      })
      if (!confirmed) return
      console.log('onToggleAllowAllVmsUnits')
      try {
        await this.$fs.doc('/auth/settings').update({ allowAllVmsUnits: !this.settings.allowAllVmsUnits })
        this.showAddModal = false
      } catch (error) {
        this.$showError(error)
      }
    },
    async onToggleAllowAll () {
      const confirmed = await this.$confirm({
        title: `Turn ${this.settings.allowAll ? 'OFF' : 'ON'} Allow All`,
        type: 'is-warning',
        message: `Are you sure you want to turn <b>${this.settings.allowAll ? 'OFF' : 'ON'}</b> Allow All`
      })
      if (!confirmed) return
      console.log('onToggleAllowAll')
      try {
        await this.$fs.doc('/auth/settings').update({ allowAll: !this.settings.allowAll })
        this.showAddModal = false
      } catch (error) {
        this.$showError(error)
      }
    },
    async onCancel (item) {
      const confirmed = await this.$confirm({
        type: 'is-danger',
        title: 'Cancelling Activation',
        message: `Are you sure you want to cancel this activation ${item.type}<p><b>${item.value}</b></p>`
      })
      if (!confirmed) return

      this.cancelling = item._id
      try {
        await this.$fs.doc(`/activations/${item._id}`).update({ status: 'cancelled' })
      } catch (error) {
        this.$showError(error)
      }
      this.cancelling = null
    },
    async onReactivate (item) {
      const confirmed = await this.$confirm({
        type: 'is-danger',
        title: 'Re-activating Code',
        message: `Are you sure you want to re-activation ${item.type}<p><b>${item.value}</b></p>`
      })
      if (!confirmed) return

      this.cancelling = item._id
      try {
        if (item.type === 'ip') {
          const doc = await this.getActiveIpActivation(item.value)
          if (doc && doc._id !== item._id) {
            throw new Error(`IP activation for ${item.value} already active`)
          }
        }
        await this.$fs.doc(`/activations/${item._id}`).update({ status: 'active' })
      } catch (error) {
        this.$showError(error)
      }
      this.cancelling = null
    },
    async genreateCode () {
      this.adding = 'loading'
      let code = this.createShortUUID(6)
      let count = 0
      try {
        let codeValid = await this.checkCode(code)
        while (!codeValid && count++ < 1000) {
          code = this.createShortUUID(6)
          codeValid = await this.checkCode(code)
        }
        if (!codeValid) {
          throw new Error('Could not create activation code! Unkown error')
        }
        await this.$fs.doc(`/activations/${code}`).set({
          type: 'code',
          name: this.newName.trim(),
          value: code,
          status: 'active',
          user: this.user.email,
          timestamp: this.$moment().unix(),
          date: new Date()
        })
        this.newName = ''
        this.newCode = code
        this.adding = 'code'
      } catch (error) {
        this.$showError(error)
        this.adding = 'new-code'
      }
    },
    async checkCode (code) {
      const ss = await this.$fs.doc(`/activations/${code}`).get()
      console.log(`${code} exists ${ss.exists}`)
      return !ss.exists
    },
    createShortUUID (length) {
      var dt = new Date().getTime()
      let uuid = ''
      for (let i = 0; i < length; i++) uuid += 'x'
      return uuid.replace(/[x]/g, c => {
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
    }
  }
}
</script>
