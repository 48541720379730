import Vue from 'vue'
import moment from 'moment'

function getMoment (value) {
  let m = null
  if (typeof value === 'object') {
    if (value.timestamp) {
      m = moment.unix(value.timestamp)
    } else if (value._seconds) {
      m = moment.unix(value.timestamp)
    } else if (typeof value.toDate === 'function') {
      m = moment(value.toDate())
    }
  } else if (typeof value === 'number' || typeof value === 'string') {
    m = moment.unix(Number(value))
  }
  return m
}

Vue.filter('formatDate', (date, format = 'DD/MM/YYYY', noVal = '-') => {
  const m = getMoment(date)
  return m ? m.format(format) : noVal
})

Vue.filter('formatDateTime', (date, noVal = '') => {
  const m = getMoment(date)
  return m ? m.format('DD/MM/YYYY h:mma') : noVal
})

Vue.filter('formatTime', (date, noVal = '') => {
  const m = getMoment(date)
  return m ? m.format('h:mma') : noVal
})

Vue.filter('unixToDateString', timestamp => {
  return Number(timestamp) ? moment.unix(Number(timestamp)).format('DD/MM/YYYY') : ''
})

Vue.filter('currency', value => {
  if (typeof value !== 'number' || isNaN(value)) return '-'
  const sign = value < 0 ? '-' : ''
  return `${sign}$${Math.abs(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
})

Vue.filter('unCamel', value => {
  return value.replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())
})

Vue.filter('camel', value => {
  return value.toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
})

Vue.filter('ucWords', value => {
  const str = `${value.trim()}`
  const arr = []
  for (let s of str.split(' ')) {
    s = s.trim()
    arr.push(s[0].toUpperCase() + s.toLowerCase().substr(1))
  }
  return arr.join(' ')
})

Vue.filter('formatDuration', value => {
  if (value === null || value === undefined) {
    return '-'
  }
  const pad = num => !num ? '00' : num < 10 ? `0${num}` : num
  const seconds = Number(value)
  if (seconds < 60) {
    return `${seconds} secs`
  }
  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    return `0:${pad(minutes)}`
  }
  const hours = Math.floor(minutes / 60)
  return `${hours}:${pad(minutes % 60)}`
})

Vue.filter('prettyDuration', (value, abbreviated = false) => {
  if (value === null || value === undefined) {
    return ''
  }

  const pluralise = (num) => num === 1 ? '' : 's'

  const secStr = abbreviated ? 'sec' : ' second'
  let seconds = Number(value)
  if (seconds < 60) {
    return `${seconds}${secStr}${pluralise(seconds)}`
  }

  const minStr = abbreviated ? 'min' : ' minute'
  let minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    seconds = seconds % 60
    let str = `${minutes}${minStr}${pluralise(minutes)}`
    if (seconds) str += ` ${seconds}${secStr}${pluralise(seconds)}`
    return str
  }

  const hrStr = abbreviated ? 'hr' : ' hour'
  const hours = Math.floor(minutes / 60)
  minutes = minutes % 60
  seconds = seconds % 60
  let str = `${hours}${hrStr}${pluralise(hours)}`
  if (minutes) str += ` ${minutes}${minStr}${pluralise(minutes)}`
  if (seconds) str += ` ${seconds}${secStr}${pluralise(seconds)}`
  return str
})

Vue.filter('prettyFileSize', value => {
  if (value === null || value === undefined) {
    return ''
  }

  let size = Number(value) / 1024
  if (size < 1000) {
    return size.toFixed(0) + ' KB'
  }
  size /= 1024
  if (size < 10) {
    return size.toFixed(2) + ' MB'
  }
  if (size < 100) {
    return size.toFixed(1) + ' MB'
  }
  if (size < 100) {
    return size.toFixed(0) + ' MB'
  }
  size /= 1024
  if (size < 10) {
    return size.toFixed(2) + ' GB'
  }
  if (size < 100) {
    return size.toFixed(1) + ' GB'
  }
  return size.toFixed(0) + ' GB'
})
