import store from '@/store'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

export default async function (action, data = {}, options = {}) {
  const user = firebase.auth().currentUser
  if (user) {
    const token = await user.getIdToken()
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }
  const url = store.getters.apiUrl + action
  const resp = await axios.post(url, data, options)
  if (resp.data.status === 'error') {
    throw new Error(resp.data.msg)
  }
  return resp.data
}
