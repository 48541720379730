<template>
  <div class="map-styles height-100">

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name flex-1">Map Styles</div>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-1">Style Name / ID</div>
        <div class="flex-table-cell flex-0 w-200px right">
          <button class="button is-success is-small" @click="onNewStyle()">
            <fa icon="plus" />
          </button>
        </div>
      </div>

      <div class="flex-table-body" v-if="styles">
        <router-link :to="`/map-styles/${s._id}`" class="flex-table-row" v-for="s in styles" :key="s._id">
          <div class="flex-table-cell flex-1">{{ s.name }}</div>
          <div class="flex-table-cell flex-0 w-200px flex">
            <div class="flex-1"></div>
            <div class="buttons">
              <button class="button is-small" @click.stop.prevent="onDelete(s)"><fa icon="times" /></button>
              <button class="button is-small" @click.stop.prevent="onNewStyle(s)">Copy</button>
              <button class="button is-small"><fa icon="edit" /></button>
            </div>
          </div>
        </router-link>
      </div>

    </div>

  </div>
</template>

<script>
import { layers } from './layers/layers'
export default {
  data () {
    return {
      styles: null
    }
  },
  created () {
    this.subs.styels = this.$fs.collection('styles')
      .onSnapshot(ss => {
        this.styles = this.$ssToArr(ss)
      })
  },
  methods: {
    async onNewStyle (style) {
      const name = await this.$prompt({
        type: 'is-info',
        title: (style ? 'Copied' : 'New') + ' Style Name'
      })
      if (name === null) return
      const id = name.toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^A-Za-z0-9 -]/g, '')
      const newStyle = style || JSON.parse(JSON.stringify(layers))
      newStyle.name = name
      newStyle._id = id
      await this.$fs.collection('styles')
        .doc(id)
        .set(newStyle)
      this.$router.push(`/map-styles/${id}`)
    },
    async onDelete (style) {
      const confirmed = await this.$confirm({
        type: 'is-danger',
        title: 'Deleting Style',
        message: `Are you sure you want to delete this style?<br /><b>${style.name}</b>`
      })
      if (!confirmed) return
      await this.$fs.doc(`styles/${style._id}`).delete()
    }
  }
}
</script>

<style lang="scss">
.map-styles {
  a { color: #333; }
}
</style>
