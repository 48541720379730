<template>
  <div class="raster-map-set-code">

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">
      <div class="toolbar flex">
        <div class="name">Raster Map Set Code</div>
        <div class="flex-1"></div>
        <div v-if="code && code !== 'none'" class="bold font-size-150">{{ code }}</div>
      </div>
      <template v-if="!codeData">
        <div class="columns">
          <div class="column is-2"></div>
          <div class="column pad-top-20">
            <div class="box margin-20">
              <form @submit.prevent="onSubmit()">
                <b-field grouped>
                  <b-input size="is-large" placeholder="ENTER MAP SET CODE" v-model="str" expanded />
                  <p class="control">
                    <button type="submit" class="button is-large is-info" size="is-large" :class="{'is-loading': codeData === null}">SEARCH</button>
                  </p>
                </b-field>
              </form>
            </div>
            <div class="bold has-text-danger" v-if="errMsg" v-html="errMsg"></div>
          </div>
          <div class="column is-2"></div>
        </div>
      </template>

      <template v-else-if="codeData">
        <div class="flex font-size-170 bg-eee" style="border-bottom: 2px solid #dbdbdb">
          <div class="pad">MAP SET ACTIVATION CODE:</div>
          <div class="flex-1 bold pad">{{ code }}</div>
        </div>

        <div class="flex-table-body">
          <div class="flex-table-row">
            <div class="flex-table-cell h">STATUS</div>
            <div class="flex-table-cell flex-3 bold flex">
              <div class="flex-1 font-size-110">
                <div v-if="!codeData.deviceInfo" class="has-text-success">AVAILABLE</div>
                <div v-else-if="codeData.deviceInfo === 'reassign'" class="has-text-info">REASSIGN</div>
                <div v-else-if="codeData.deviceInfo === 'disabled'" class="has-text-danger">DISABLED</div>
                <div v-else class="has-tex-success flex">
                  <div class="flex-1">USED</div>
                  <button class="button is-small" @click="reassign()">reassign</button>
                </div>
              </div>
              <div>
                <button v-if="codeData.deviceInfo !== 'disabled'" class="button is-small is-danger" @click="setEnable(false)">disable</button>
                <button v-else class="button is-small is-info" @click="setEnable(true)">enable</button>
              </div>
            </div>
          </div>

          <div class="flex-table-row" v-if="codeData.activations">
            <div class="flex-table-cell h">ACTIVATIONS</div>
            <div class="flex-table-cell flex-3">
              <div class="flex bold has-text-grey-light">
                <div class="flex-1">IP Address</div>
                <div class="flex-1">App ID</div>
                <div class="flex-1">Device Info</div>
                <div class="flex-1">Timestamp</div>
              </div>
              <div class="flex" v-for="(activation, i) in codeData.activations" :key="i">
                <div class="flex-1">
                  <template v-if="activation.ip !== 'NA'">
                    <ip-address :ip="activation.ip">
                      <div class="flex">
                        <div class="flex-1">{{ activation.ip }}</div>
                        <div>{{ activation.timestamp | formatDateTime }}</div>
                      </div>
                    </ip-address>
                  </template>
                  <template v-else>{{ activation.ip }}</template>
                </div>
                <div class="flex-1">{{ activation.deviceInfo.appName }}</div>
                <div class="flex-1">
                  <template v-if="typeof activation.deviceInfo === 'string'">
                    <span class="bold has-text-grey uc">{{ activation.deviceInfo }}</span>
                  </template>
                  <template v-else>
                    <device-info :one-line="true" :device-info="activation.deviceInfo" />
                  </template>
                </div>
                <div class="flex-1">{{ activation.timestamp | formatDateTime }}</div>
              </div>
            </div>
          </div>

          <div class="flex-table-row" v-if="batchData">
            <div class="flex-table-cell h">Map Set Code Batch</div>
            <div class="flex-table-cell flex-3 flex">
              {{ batchData.name }}
              <span class="has-text-grey-light italic pad-left-20">{{ batchData.usedCount }} / {{ batchData.codeCount }} codes used</span>
            </div>
          </div>
          <div class="flex-table-row" v-if="mapSetData">
            <div class="flex-table-cell h">Map Set</div>
            <div class="flex-table-cell flex-3">{{ mapSetData.name }}</div>
          </div>
          <div class="flex-table-row" v-if="mapSetData">
            <div class="flex-table-cell h">Maps</div>
            <div class="flex-table-cell flex-3">
              <div class="flex bold has-text-grey-light">
                <div class="flex-1">Publisher</div>
                <div class="flex-5">Name</div>
                <div class="flex-2">Description</div>
              </div>
              <div v-for="(map, i) in mapSetData.maps" :key="i" class="flex">
                <div class="flex-1">{{ map.publisher }}</div>
                <div class="flex-5">{{ map.name }}</div>
                <div class="flex-2">{{ map.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import IpAddress from '@/components/IpAddress'
import DeviceInfo from '@/components/DeviceInfo'
export default {
  components: { IpAddress, DeviceInfo },
  data () {
    return {
      str: '',
      codeData: null,
      mapSetData: null,
      batchData: null,
      errMsg: null
    }
  },
  computed: {
    code () { return this.$route.params.code }
  },
  watch: {
    code () { this.fetchCode() }
  },
  created () {
    this.fetchCode()
  },
  methods: {
    onSubmit () {
      console.log('onSubmit', this.str)
      const code = this.str.replace(/[^0-9a-z]/gi, '')
      if (code.length >= 6 && this.str === code && code !== this.code) {
        this.$router.push(`/raster-maps/set-code/${code}`)
      } else {
        this.errMsg = '<b>INVALID CODE</b>'
      }
    },
    fetchCode () {
      this.unsub('code')
      this.codeData = null
      this.fetchCodeMapSet()
      this.fetchCodeBatch()
      if (!this.code || this.code === 'none') {
        this.codeData = false
        return
      }
      this.str = this.code
      this.subs.code = this.$fs.doc(`/rasterMapSetCodes/${this.code.trim()}`)
        .onSnapshot(ss => {
          this.codeData = this.$ssToObj(ss)
          this.fetchCodeMapSet()
          this.fetchCodeBatch()
          this.errMsg = this.codeData ? null : `<b>INVALID CODE:</b> ${this.code}`
        })
    },
    fetchCodeMapSet () {
      if (this.codeData && this.mapSetData && this.codeData.mapSetId === this.mapSetData._id) return
      this.unsub('codeMapSet')
      this.mapSetData = null
      if (!this.codeData) return
      this.$fs.doc(`/rasterMapSets/${this.codeData.mapSetId}`)
        .onSnapshot(ss => {
          this.mapSetData = this.$ssToObj(ss)
        })
    },
    fetchCodeBatch () {
      if (this.codeData && this.batchData && this.codeData.codeBatchId === this.batchData._id) return
      this.unsub('codeMapBatch')
      this.batchData = null
      if (!this.codeData) return
      this.$fs.doc(`/rasterMapSetCodeBatches/${this.codeData.codeBatchId}`)
        .onSnapshot(ss => {
          this.batchData = this.$ssToObj(ss)
        })
    },
    async reassign () {
      if (!this.codeData || !this.codeData.deviceInfo || this.codeData.deviceInfo === 'reassign') return
      const confirmed = await this.$confirm({
        type: 'is-warning',
        title: 'REASSIGN CODE',
        message: `
          <p class="bold">Are you sure wou want to reassign this activation code?</p>
          <p>When reassigned the code will beceom available to be activated and assinged to another device</p>
          <p>It will no londer be available on the current device!</p>`
      })
      if (!confirmed) return
      try {
        await this.$api('reassign-map-set-code', { code: this.codeData.code })
      } catch (error) {
        this.$showError(error)
      }
    },
    async setEnable (enable) {
      const confirmed = await this.$confirm({
        type: enable ? 'is-info' : 'is-danger',
        title: enable ? 'ENABLE CODE' : 'DISABLE CODE',
        message: `<p class="bold">Are you sure wou want to ${enable ? 'enable' : 'disable'} this activation code?</p>`
      })
      if (!confirmed) return
      console.log('setEnabled', enable)
      try {
        await this.$api('enable-map-set-code', { code: this.codeData.code, enable })
      } catch (error) {
        this.$showError(error)
      }
    }
  }

}
</script>
