<template>
  <div class="raster-map-set height-100">
    <edit-raster-map-modal :id="editMapId" :show.sync="showEditMap" />

    <b-modal :active.sync="showAddCodesModal">
      <div class="box flex-col">
        <div class="bold font-size-150">Add Activations Code Batch</div>
        <div class="flex-1 pad-top">
          <div class="columns is-multiline">
            <div class="column is-8">
              <b-field label="User Email">
                <b-input :value="user.email" :disabled="true" />
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Date Created">
                <b-input :value="$options.filters.formatDate($moment().unix())" :disabled="true" />
              </b-field>
            </div>
            <div class="column is-8">
              <b-field label="Batch Name">
                <b-input v-model="newCodeBatchName" />
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Count">
                <b-input min="0" v-model="newCodeBatchCount" type="number" />
              </b-field>
            </div>
          </div>
        </div>
        <div class="buttons flex pad-top">
          <button class="button" @click="showAddCodesModal = false">Cancel</button>
          <div class="flex-1"></div>
          <button class="button is-success"
            :class="{'is-loading': loading === 'newCodeBatch'}"
            :disabled="!newCodeBatchName.trim().length || newCodeBatchName <= 0"
            @click="submitCodeBatch()"
          >Add Codes</button>
        </div>
      </div>
    </b-modal>

    <div v-if="mapSet === null" class="height-100 flex-middle font-size-200 has-text-grey-light">
      <div>
        <fa icon="spinner" size="2x" pulse />
      </div>
    </div>

    <div v-else-if="!mapSet" class="pad-20">
      <b-message type="is-danger">Could no load raster map set?</b-message>
    </div>

    <div v-else class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name">Raster Map Set:</div>
        <div class="bold name color-666">{{ mapSet.name }}</div>
        <div class="flex-1"></div>
        <div class="pad-top-5">{{ mapSet.userEmail }}</div>
        <div class="pad-top-5 pad-left bold color-666">({{ mapSet.timestampCreated | formatDate }})</div>
      </div>

      <div class="flex-table-header overflow-y-hidden">
        <div class="flex-table-cell flex-0 tab-name" :class="{current: tab === 'codes'}" @click="tab = 'codes'">Activation Codes</div>
        <div class="flex-table-cell flex-0 tab-name" :class="{current: tab === 'maps'}" @click="tab = 'maps'">Maps</div>
        <div class="flex-table-cell flex-1 bg-fff right">
          <button class="button is-danger is-small"
            v-if="user.email === 'shaun@adventuretech.net.au'"
            :class="{'is-loading': loading === 'deleteMapSet'}"
            @click="deleteMapSet()">
            <fa icon="times" />
          </button>
        </div>
      </div>

      <template v-if="tab === 'maps'">
        <div class="flex-table-header">
          <div class="flex-table-cell flex-2">Publisher</div>
          <div class="flex-table-cell flex-4">
            Name
            <p>Description</p>
          </div>
          <div class="flex-table-cell flex-1">Zoom</div>
          <div class="flex-table-cell flex-3">
            URL
            <p>File Name</p>
          </div>
          <div class="flex-table-cell flex-3">
            Size
            <p>Checksum</p>
          </div>
          <div class="flex-table-cell flex-0 w-60px"></div>
        </div>
        <div v-if="maps" class="flex-table-body">
          <div v-for="item in maps" :key="item._id" class="flex-table-row" :class="{selected: item.selected}">
            <div class="flex-table-cell flex-2 pointer">{{ item.publisher }}</div>
            <div class="flex-table-cell flex-4 pointer">
              {{ item.name }}
              <p class="font-size-80">{{ item.description }}</p>
            </div>
            <div class="flex-table-cell flex-1 pointer">{{ item.minZ }} to {{ item.maxZ }}</div>
            <div class="flex-table-cell flex-3 pointer">
              {{ item.url }}
              <p class="font-size-80">{{ item.fileName }}</p>
            </div>
            <div class="flex-table-cell flex-3 pointer">
              {{ item.bytes_total | prettyFileSize }}
              <p class="font-size-80">{{ item.checksum }}</p>
            </div>
            <div class="flex-table-cell flex-0 w-60px">
              <button class="button is-small" @click="editMap(item._id)"><fa icon="edit" /></button>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="tab === 'codes'">
        <div class="flex-table-header">
          <div class="flex-table-cell flex-1">Date Created</div>
          <div class="flex-table-cell flex-3">User Email</div>
          <div class="flex-table-cell flex-3">Name</div>
          <div class="flex-table-cell flex-1">Count</div>
          <div class="flex-table-cell flex-4">Codes</div>
          <div class="flex-table-cell flex-0 w-60px">
            <button class="button is-small is-success" @click="showAddCodeBatch()"><fa icon="plus" /></button>
          </div>
        </div>
        <div v-if="codeBatches" class="flex-table-body">
          <div v-for="item in codeBatches" :key="item._id" class="flex-table-row pointer">
            <div class="flex-table-cell flex-1 pointer">{{ item.timestampCreated | formatDate }}</div>
            <div class="flex-table-cell flex-3 pointer">{{ item.userEmail }}</div>
            <div class="flex-table-cell flex-3 pointer">{{ item.name }}</div>
            <div class="flex-table-cell flex-1 pointer">{{ item.usedCount }} / {{ item.codeCount }}</div>
            <div class="flex-table-cell flex-4">
              <template v-if="selectedCodeBatchId === item._id">
                <div v-for="codeItem in codes || []" :key="codeItem._id" class="flex">
                  <div class="flex-0 w-150px bold">
                    <router-link :to="`/raster-maps/set-code/${codeItem.code}`" class="has-text-info underline">{{ codeItem.code }}</router-link>
                  </div>
                  <div class="flex-1">
                    <template v-if="!codeItem.activations.length">NO USED</template>
                    <template v-else>
                      <div v-for="(a, i) in codeItem.activations" :key="i">
                        {{ a.timestamp | formatDateTime }}
                        <span v-if="a.deviceInfo === 'reassign'">(reassign)</span>
                      </div>
                    </template>
                  </div>
                </div>
                <button class="button is-small margin-top-5" @click="selectedCodeBatchId = null">hide codes</button>
              </template>
              <template v-else>
                <button class="button is-small" @click="selectedCodeBatchId = item._id">view codes</button>
              </template>
            </div>
            <div class="flex-table-cell flex-0 w-60px"></div>
          </div>
        </div>
      </template>

    </div>

  </div>
</template>

<script>
import EditRasterMapModal from '@/components/EditRasterMapModal.vue'
export default {
  components: { EditRasterMapModal },
  data () {
    return {
      mapSet: null,
      codeBatches: null,
      codes: null,
      selectedCodeBatchId: null,
      tab: 'maps',
      showAddCodesModal: false,
      newCodeBatchName: '',
      newCodeBatchCount: 0,
      loading: null,
      editMapId: null,
      showEditMap: false
    }
  },
  computed: {
    user () { return this.$store.getters.user },
    id () { return this.$route.params.id },
    maps () { return this.mapSet ? this.mapSet.maps : null }
  },
  watch: {
    id () { this.fetch() },
    selectedCodeBatchId () { this.fetchCodes() }
  },
  created () { this.fetch() },
  methods: {
    editMap (mapId) {
      this.editMapId = mapId
      this.showEditMap = true
    },
    fetch () {
      this.unsub('mapSet')
      this.mapSet = null
      this.unsub('maps')
      if (!this.id) return
      this.subs.mapSet = this.$fs.doc(`/rasterMapSets/${this.id}`)
        .onSnapshot(ss => {
          this.mapSet = this.$ssToObj(ss)
        })
      this.subs.codeBatches = this.$fs.collection('rasterMapSetCodeBatches')
        .where('mapSetId', '==', this.id)
        .orderBy('timestampCreated', 'desc')
        .onSnapshot(ss => {
          this.codeBatches = this.$ssToArr(ss)
        })
    },
    showAddCodeBatch () {
      console.log('showAddCodeBatch')
      this.newCodeBatchName = ''
      this.newCodeBatchCount = 0
      this.showAddCodesModal = true
    },
    async submitCodeBatch () {
      console.log('submitCodeBatch')
      this.loading = 'newCodeBatch'
      try {
        console.log(this.user.email)
        this.newCodeBatchName = this.newCodeBatchName.trim()
        await this.$api('new-map-set-code-batch', {
          mapSetId: this.id,
          name: this.newCodeBatchName,
          codeCount: this.newCodeBatchCount,
          userEmail: this.user.email,
          timestampCreated: this.$moment().unix()
        })
        this.showAddCodesModal = false
      } catch (error) {
        this.$showError(error)
      }
      this.loading = null
    },
    fetchCodes () {
      this.unsub('codes')
      this.codes = null
      if (!this.selectedCodeBatchId) return
      this.subs.codes = this.$fs.collection('rasterMapSetCodes')
        .where('codeBatchId', '==', this.selectedCodeBatchId)
        .onSnapshot(ss => {
          this.codes = this.$ssToArr(ss)
        })
    },
    async deleteMapSet () {
      const confirmed = await this.$confirm({
        type: 'is-danger',
        title: 'Delete Map Set',
        message: 'Are you sure you want to delete this map set. This cannot be undone'
      })
      if (!confirmed) return

      this.loading = 'deleteMapSet'
      try {
        const resp = await this.$api('delete-map-set', { mapSetId: this.id })
        console.log(resp)
        this.$back()
      } catch (error) {
        this.$showError(error)
      }
      this.loading = null
    }
  }
}
</script>

<style lang="scss">
.raster-map-set {
  .tab-name {
    padding: 10px 20px !important;
    cursor: pointer;
    font-weight: normal !important;
  }
  .tab-name.current {
    font-weight: bold !important;
    background: #ddd;
  }

  .modal-content .box {
    height: auto;
  }
}
</style>
