import { road } from './road'
import { waterway } from './waterway'

const water = {
  name: 'water',
  type: 'polygon',
  styles: [
    { name: 'water' }
  ]
}

const background = {
  name: 'background',
  type: 'polygon',
  styles: [
    { name: 'background' }
  ]
}

const ocean = {
  name: 'ocean',
  type: 'polygon',
  styles: [
    { name: 'ocean' }
  ]
}

const landuse = {
  name: 'landuse',
  type: 'polygon',
  styles: [
    { name: 'commercial' },
    { name: 'industrial' },
    { name: 'residential' },
    { name: 'retail' },
    { name: 'allotments' },
    { name: 'cemetery' },
    { name: 'depot' },
    { name: 'garages' },
    { name: 'military' },
    { name: 'port' },
    { name: 'quarry' },
    { name: 'railway' },
    { name: 'recreation_ground' },
    { name: 'religious' }
  ]
}

const landcover = {
  name: 'landcover',
  type: 'polygon',
  styles: [
    { name: 'natural_wetland' },
    { name: 'natural_grassland' },
    { name: 'natural_wood' },
    { name: 'natural_reef' },
    { name: 'natural_glacier' },
    { name: 'natural_scrub' },
    { name: 'natural_heath' },
    { name: 'natural_bare_rock' },
    { name: 'natural_shingle' },
    { name: 'natural_scree' },
    { name: 'natural_sand' },
    { name: 'natural_mud' },
    { name: 'natural_beach' },
    { name: 'natural_dune' },
    { name: 'natural_volcano' },
    { name: 'landuse_farmland' },
    { name: 'landuse_farmyard' },
    { name: 'landuse_forest' },
    { name: 'landuse_meadow' },
    { name: 'landuse_orchard' },
    { name: 'landuse_vineyard' },
    { name: 'landuse_basin' },
    { name: 'landuse_brownfield' },
    { name: 'landuse_grass' },
    { name: 'landuse_greenfield' },
    { name: 'landuse_greenhouse_horticulture' },
    { name: 'landuse_landfill' },
    { name: 'landuse_plant_nursery' },
    { name: 'landuse_reservoir' },
    { name: 'landuse_salt_pond' },
    { name: 'landuse_village_gree' }
  ]
}

const park = {
  name: 'park',
  type: 'polygon',
  styles: [
    { name: 'boundary_national_park' },
    { name: 'leisure_nature_reserve' },
    { name: 'dog_park' },
    { name: 'garden' },
    { name: 'golf_course' },
    { name: 'marina' },
    { name: 'park' },
    { name: 'playground' },
    { name: 'pitch' },
    { name: 'sports_centre' },
    { name: 'stadium' },
    { name: 'swimming_pool' },
    { name: 'track' }
  ]
}

const building = {
  name: 'building',
  type: 'polygon',
  styles: [
    { name: 'building' }
  ]
}

const boundary = {
  name: 'boundary',
  type: 'line',
  styles: [
    { name: 'boundary' }
  ]
}

const layers = {
  background,
  ocean,
  landcover,
  landuse,
  park,
  water,
  waterway,
  road,
  building,
  boundary
}

let layerIndex = 0
for (const layerKey in layers) {
  const layer = layers[layerKey]
  layer.i = layerIndex
  for (let i = 0; i < layer.styles.length; i++) {
    layer.styles[i].i = i
  }
  layerIndex++
}

const maxZ = 18

export {
  layers,
  maxZ
}
