const zoomLevels = [
  { z: 7, classes: ['river'] },
  { z: 10, classes: ['river', 'canal'] },
  { z: 13, classes: ['river', 'canal', 'stream', 'drain'] },
  { z: 14, classes: ['river', 'riverbank', 'stream', 'tidal_channel', 'canal', 'pressurised', 'drain', 'ditch', 'fairway', 'dam'] }
]

const keys = [
  'river',
  'riverbank',
  'stream',
  'tidal_channel',
  'canal',
  'pressurised',
  'drain',
  'ditch',
  'fairway',
  'dam'
]

const waterway = {
  name: 'waterway',
  type: 'line',
  styles: []
}

function getMinZ (key) {
  for (const zoomLevel of zoomLevels) {
    if (zoomLevel.classes.includes(key)) return zoomLevel.z
  }
  throw new Error(`invalud min zoom lever for waterway ${key}`)
}

for (const name of keys) {
  waterway.styles.push({ name, minZ: getMinZ(name) })
}

export { waterway }
