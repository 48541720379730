import Vue from 'vue'
import Buefy, { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import moment from 'moment'

import App from '@/App.vue'
import router from '@/router.js'
import store from '@/store.js'
import api from '@/utils/api'
import '@/firebase'
import '@/filters.js'

import 'buefy/dist/buefy.css'
import '@/assets/style/flex-table.css'
import '@/assets/style/sdf.css'
import '@/assets/style/style.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/pro-solid-svg-icons'

library.add(fas)
Vue.component('fa', FontAwesomeIcon)

Vue.use(Buefy)
Vue.config.productionTip = false

Vue.prototype.$api = api
Vue.prototype.$moment = moment
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
Vue.prototype.$wait = (ms) => new Promise(resolve => setTimeout(resolve, ms))
Vue.prototype.$back = () => router.go(-1)
Vue.prototype.$showAlert = (msg, title = 'Alert', type = 'is-info') => Dialog.alert({ type, title, message: msg.toString() })
Vue.prototype.$showError = (err, title = 'Error') => Dialog.alert({ type: 'is-danger', title, message: err.message || err.toString() })
Vue.prototype.$log = msg => console.log(msg)
Vue.prototype.$nl2br = str => (str || '').replace(/(?:\r\n|\r|\n)/g, '<br>')
Vue.prototype.$unescape = str => unescape(str || '')
Vue.prototype.$escape = str => escape(str || '')
Vue.prototype.$confirm = (options) => {
  return new Promise(resolve => {
    options.onConfirm = () => resolve(true)
    options.onCancel = () => resolve(false)
    Dialog.confirm(options)
  })
}
Vue.prototype.$prompt = (options = {}) => {
  return new Promise(resolve => {
    options.onConfirm = (value) => resolve(value)
    options.onCancel = () => resolve(null)
    Dialog.prompt(options)
  })
}

Vue.mixin({
  data () { return { subs: {} } },
  destroyed () { for (const k in this.subs || {}) this.unsub(k) },
  methods: {
    unsub (k) {
      if (typeof this.subs[k] === 'function') {
        this.subs[k]()
        this.subs[k] = null
      }
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
