<template>
  <div class="test pad">

    <button class="button" :class="{ 'is-loading': loading }" @click="go()">Go</button>

  </div>
</template>

<script>
import firebase from 'firebase/app'
export default {
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async go () {
      this.loading = true
      try {
        const ss = await this.$fs.collection('rasterMaps').get()
        const batch = this.$fs.batch()
        for (const map of this.$ssToArr(ss)) {
          batch.update(this.$fs.doc(`/rasterMaps/${map._id}`), { mapSets: firebase.firestore.FieldValue.delete() })
          console.log(map.name, map.mapSets)
        }
        await batch.commit()
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    }
  }
}
</script>
