import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const getSets = {
  user: null,
  itemLimit: 50
}

const storeSettings = {
  state: {},
  getters: {},
  mutations: {}
}

for (const k in getSets) {
  storeSettings.state[k] = getSets[k]
  storeSettings.getters[k] = (state) => state[k]
  storeSettings.mutations[k] = (state, payload) => { state[k] = payload }
}

storeSettings.getters.apiUrl = () => {
  if (document.location.hostname === 'localhost') {
    return 'http://10.0.0.107:5002/adventure-map-admin/us-central1/api/'
  } else {
    return 'https://us-central1-adventure-map-admin.cloudfunctions.net/api/'
  }
}

const store = new Vuex.Store(storeSettings)
export default store
