<template>
  <div class="add-raster-maps height-100">

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="flex-1 name">
          Add Raster Maps
        </div>
      </div>

      <template v-if="!uploading">
        <div class="flex-table-body pad">

          <div class="section-box margin-bottom-20">
            <div class="bold font-size-140 margin-bottom">Batch Upload JSON string</div>
            <b-input type="textarea" v-model="jsonStr" />
            <div class="buttons margin-top">
              <button class="button is-success" :disabled="!jsonArr" @click="submitJson()">Upload Json Str</button>
            </div>
          </div>

          <div class="section-box flex-1 overflow-y-auto">
            <div class="bold font-size-140 margin-bottom">Add Single Map</div>
            <sdf-form :fields="fields" ref="sdfForm" @input="onFormInput" />
            <div class="buttons">
              <button class="button is-success" :disabled="!formComplete" @click="submitForm()">Add Single Map</button>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="flex-table-header">
          <div class="flex-table-cell flex-2">Publisher</div>
          <div class="flex-table-cell flex-4">Name</div>
          <div class="flex-table-cell flex-2">Description</div>
          <div class="flex-table-cell flex-1">Min Z</div>
          <div class="flex-table-cell flex-1">Max Z</div>
          <div class="flex-table-cell flex-2">STATUS</div>
        </div>

        <div v-if="progress" class="flex-table-body">
          <div v-for="(item, i) in progress" :key="i" class="flex-table-row">
            <div class="flex-table-cell flex-2">{{ item.map.publisher }}</div>
            <div class="flex-table-cell flex-4">{{ item.map.name }}</div>
            <div class="flex-table-cell flex-2">{{ item.map.description }}</div>
            <div class="flex-table-cell flex-1">{{ item.map.minZ }}</div>
            <div class="flex-table-cell flex-1">{{ item.map.maxZ }}</div>
            <div class="flex-table-cell flex-2">
              {{ item.status }}
              <fa v-if="item.status === 'uploading'" icon="spinner" pulse />
            </div>
          </div>
        </div>

        <div class="toolbar flex">
          <div class="name">
            UPLOADING STATUS:
            <span class="text-999 uc">
              {{ uploading }} <fa v-if="uploading === 'uploading'" icon="spinner" pulse />
            </span>
          </div>
          <div class="flex-1"></div>
          <div class="buttons">
            <button class="button" :disabled="uploading === 'uploading'" @click="closeUploading()">CLOSE</button>
            <button class="button is-danger" :disabled="uploading !== 'uploading'" uploading = 'cancel'>CANCEL</button>
          </div>
        </div>
      </template>

    </div>

  </div>
</template>

<script>
import SdfForm from '@/components/SdfForm'
export default {
  components: { SdfForm },
  data () {
    return {
      uploading: null,
      jsonStr: '',
      fields: [
        { key: 'publisher', required: true },
        { key: 'name', required: true },
        { key: 'description', required: true },
        { key: 'minZ', input: 'number', required: true },
        { key: 'maxZ', input: 'number', required: true }
      ],
      jsonArr: null,
      formComplete: false,
      debounceTimer: null,
      progress: []
    }
  },
  watch: {
    jsonStr () {
      clearTimeout(this.debounceTimer)
      this.jsonObj = null
      this.debounceTimer = setTimeout(this.onJsonStrUpdate, 200)
    }
  },
  methods: {
    onJsonStrUpdate () {
      try {
        this.jsonArr = JSON.parse(this.jsonStr)
        if (!Array.isArray(this.jsonArr)) this.jsonArr = null
      } catch (error) {
        this.jsonArr = null
      }
    },
    onFormInput (data) {
      console.log(data)
      for (const field of this.fields) {
        const value = data[field.key]
        console.log(field.key, value)
        if (!value || !`${value}`.trim().length) {
          this.formComplete = false
          return
        }
      }
      this.formComplete = true
    },
    submitJson () {
      this.submitMaps(this.jsonArr)
    },
    submitForm () {
      const data = this.$refs.sdfForm.submit()
      if (!data) return
      this.submitMaps([data])
    },
    async submitMaps (maps) {
      this.progress = maps.map(map => {
        return { map, status: 'pending' }
      })
      this.uploading = 'uploading'
      let i = 0
      for (const map of maps) {
        if (this.uploading === 'cancel') break
        map.uid = this.getId(map)
        const prog = this.progress[i++]
        prog.status = 'uploading'
        try {
          const ss = await this.$fs.collection('rasterMaps')
            .where('uid', '==', map.uid)
            .get()
          if (this.$ssToObj(ss)) {
            prog.status = 'already exists'
            continue
          }
        } catch (error) {
          console.log(error)
          prog.status = 'error checking if exists'
        }

        if (this.uploading === 'cancel') break
        try {
          await this.$fs.collection('rasterMaps').add(map)
          prog.status = 'complete'
        } catch (error) {
          console.log(error)
          prog.status = 'error checking if exists'
        }
      }
      this.uploading = 'complete'
    },
    getId (map) {
      return `${map.publisher.trim().toLocaleUpperCase()} ${map.name.trim().toLocaleLowerCase()} `
    },
    closeUploading () {
      this.jsonStr = ''
      this.progress = []
      this.uploading = false
    }

  }
}
</script>
