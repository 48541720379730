<template>
  <div class="settings height-100">

    <div v-if="loading" class="height-100 flex-middle font-size-200 has-text-grey-light">
      <div>
        <fa icon="spinner" size="2x" pulse />
      </div>
    </div>

    <div v-else class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="flex-1 name">
          Engine Settings
        </div>
        <div class="buttons">
          <router-link class="button is-info" to="/icons">Icons</router-link>
        </div>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-1">ID</div>
        <div class="flex-table-cell flex-1">App Name</div>
        <div class="flex-table-cell flex-1">Timestamp</div>
        <div class="flex-table-cell flex-0 w-250px right">
          <button @click="add" class="button is-small is-success"><fa icon="plus" class="margin-right" /> New Engine Settings</button>
        </div>
      </div>

      <div v-if="items" class="flex-table-body">
        <router-link :to="`/engine-settings/${item._id}`" v-for="item in items" :key="item._id" class="flex-table-row">
          <div class="flex-table-cell flex-1">{{ item._id }}</div>
          <div class="flex-table-cell flex-1">{{ item.appName }}</div>
          <div class="flex-table-cell flex-1">{{ item.timestamp | formatDateTime }}</div>
          <div class="flex-table-cell flex-0 w-250px center">
            <div class="buttons">
              <button @click.stop.prevent="dupe(item)" class="button is-small">Duplicate</button>
              <router-link :to="`/engine-settings/banners/${item._id}`" class="button is-small">Banners</router-link>
              <button class="button is-small"><fa icon="edit" /></button>
            </div>
          </div>
        </router-link>
      </div>

    </div>

  </div>
</template>

<script>
import defaultEngineSettings from '@/assets/defaultEngineSettings.json'
export default {
  data () {
    return {
      loading: false,
      items: null
    }
  },
  created () {
    this.loading = 'FETCHING SETTINGS LIST'
    this.subs.engineSettings = this.$fs.collection('engineSettings')
      .onSnapshot(ss => {
        this.items = this.$ssToArr(ss)
        this.loading = false
      })
  },
  methods: {
    add () {
      this.promtNew(defaultEngineSettings)
    },
    dupe (data) {
      this.promtNew(data, `Duplicate ${data._id}`)
    },
    promtNew (data, title = 'New Engine Settings') {
      this.$buefy.dialog.prompt({
        title,
        type: 'is-info',
        message: 'Enter a uniquie ID for the new engine settings.<br />(Alpha-numeric characters only)',
        confirmText: 'Add Settings',
        inputAttrs: {
          placeholder: 'ID'
        },
        trapFocus: true,
        onConfirm: (value) => this.submitNew(value, data)
      })
    },
    async submitNew (name, data) {
      this.loading = true
      try {
        name = name.toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^A-Za-z0-9 -]/g, '')
        this.loading = `ADDIND ${name}`
        const docRef = this.$fs.doc(`/engineSettings/${name}`)
        const ss = await docRef.get()
        if (ss.exists) {
          throw new Error(`The name "${name}" is already in use`)
        }
        data._id = name
        await this.$fs.doc(`/engineSettings/${name}`).set(data)
        this.$router.push(`/engine-settings/${name}`)
      } catch (error) {
        this.$showError(error)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.settings {
  .modal .modal-content { width: auto; }
  a { color: #333; }
  .buttons {
    justify-content: flex-end;
  }
}
</style>
