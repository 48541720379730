<template>

  <span class="ip-address">
    <b-modal :active.sync="showModal">
      <div class="box flex-col">
        <div class="bold font-size-150"><slot></slot></div>
        <div class="flex-1 flex-middle font-size-200 has-text-grey-light" v-if="info === null">
          <fa icon="spinner" pulse />
        </div>
        <div v-if="info" class="flex-1 overflow-y-auto pad border-eee margin-top">
          <div v-for="(value, key) in info" :key="key" class="flex">
            <div class="flex-1 bold">{{ key | unCamel }}</div>
            <div class="flex-2">{{ value }}</div>
          </div>
        </div>
      </div>
    </b-modal>
    <span class="ip has-text-info pointer underline" @click="showInfo()">{{ ip }}</span>
  </span>

</template>

<script>
export default {
  props: ['ip'],
  data () {
    return {
      showModal: false,
      info: false
    }
  },
  methods: {
    async showInfo () {
      this.showModal = true
      this.info = null
      try {
        const resp = await this.$api('/ip-lookup', { ip: this.ip })
        this.info = resp.info
        console.log(this.info)
      } catch (error) {
        console.log(error)
        this.info = { error: error.message || error.toString() || '?' }
      }
    }
  }
}
</script>
