<template>
  <div class="home height-100">

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name flex-1">Authentication</div>
        <div class="name has-text-grey pad-right-20">{{ total }} App Activations</div>
        <b-dropdown :triggers="['hover']" aria-role="list" position="is-bottom-left">
          <template #trigger>
            <button class="button"><fa icon="caret-down" /></button>
          </template>
          <b-dropdown-item has-link aria-role="listitem">
            <router-link to="/auth/devices">Devices</router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="listitem">
            <router-link to="/auth/activations">Activations</router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="listitem">
            <router-link to="/auth/log">Log</router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-1">App Name</div>
        <div class="flex-table-cell flex-1">Count</div>
      </div>

      <div class="flex-table-body">
          <div class="flex-table-row" v-for="(count, app) in counts" :key="app">
            <div class="flex-table-cell flex-1">{{ app }}</div>
            <div class="flex-table-cell flex-1">{{ count }}</div>
          </div>
          <div class="flex-table-row bold">
            <div class="flex-table-cell flex-1">TOTAL</div>
            <div class="flex-table-cell flex-1">{{ total }}</div>
          </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      total: null,
      counts: null
    }
  },
  created () {
    this.subs.counts = this.$fs.doc('/auth/counts')
      .onSnapshot(ss => {
        this.counts = this.$ssToObj(ss)
        this.total = this.counts.total || null
        delete this.counts.total
        delete this.counts._id
      })
  }
}
</script>
