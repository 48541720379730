<template>
  <form class="sdf-form" :class="containerClass" @submit.prevent="submit()" :autocomplete="autocomplete">

    <slot></slot>

    <template  v-for="(field, index) in fields">
      <div :class="[field.class || fieldClass]" :key="field.key" v-if="showField(field)" :style="field.style || ''">
        <div class="field" :class="{'is-horizontal': field.horizontal}" v-if="field.key || field.title || index">
          <!-- LABEL -->
          <label class="label" v-if="!hideLabels">
            {{ getTitle(field) }}
          </label>
          <template v-if="!editing || !field.key || field.value || field.readonly">
            <p class="control value"
              :class="{ pointer: field.input === 'file' }"
              @click.stop="field.input === 'file' ? $emit('file-click', data ? data[field.key] : null, field) : null">
              {{ getValue(field) }}
            </p>
          </template>

          <template v-else-if="field.key">
            <!-- DATE -->
            <template v-if="field.input === 'date'">
              <div class="datepicker-container">
                <b-datepicker
                  v-model="dates[field.key]"
                  :class="field._class"
                  @focus="onFocus(field)"
                  @input="onInput(field)"
                  icon-pack="fas"
                  :mobile-native="true"
                  :editable="true"
                  placeholder="DD/MM/YYYY"
                  :date-formatter="(date) => $moment(date).format('DD/MM/YYYY')"
                  :date-parser="(str) => $moment(str, 'DD/MM/YYYY').toDate()"
                  icon="calendar-check">
                  <div class="has-text-right">
                    <button class="button is-small" @click.prevent="dates[field.key] = null">Clear</button>
                    &nbsp;
                    <button class="button is-primary is-small" @click.prevent="dates[field.key] = new Date()">Today</button>
                  </div>
                </b-datepicker>
              </div>
            </template>

            <!-- TEXT AREA -->
            <template v-else-if="field.input === 'textarea'">
              <textarea
                class="textarea"
                :class="field._class"
                :rows="field.rows || 4"
                :placeholder="getPlaceholder(field)"
                v-model="editData[field.key]"
                @focus="onFocus(field)"
                @input="onInput(field)"
              ></textarea>
            </template>

            <!-- SEECT -->
            <template v-else-if="field.input === 'select'">
              <div class="select" :class="[field._class, field.expanded ? 'is-fullwidth': '']">
                <b-select :expanded="field.expanded"
                  :placeholder="getPlaceholder(field)"
                  v-model="editData[field.key]"
                  @focus="onFocus(field)"
                  @input="onInput(field)"
                >
                  <option v-for="option in field._options" :key="option.value" :value="option.value">{{ option.title }}</option>
                </b-select>
              </div>
            </template>

            <!-- RADIO -->
            <template v-else-if="field.input === 'radio'">
              <div class="block">
                <b-radio v-for="option in field._options"
                  :key="option.value" v-model="editData[field.key]"
                  :native-value="option.value"
                  @focus="onFocus(field)"
                  @input="onInput(field)">
                  {{ option.title }}
                </b-radio>
              </div>
            </template>

            <!-- CHECKBOX -->
            <template v-else-if="field.input === 'checkbox'">
              <b-checkbox
                v-model="editData[field.key]"
                @input="onInput(field)"
              >
                {{ editData[field.key] ? field.trueText || 'Yes' : field.falseText || 'No' }}
              </b-checkbox>
            </template>

            <!-- FILE -->
            <template v-else-if="field.input === 'file'">
              <template v-if="field._fileState.status !== 'none'">
                <p class="control" expanded>
                  <button class="button is-static is-fullwidth" disabled>
                    <div class="flex width-100">
                      <div class="pad-right-10"><fa icon="spinner" pulse /></div>
                      <div class="flex-1 text-left">{{ field._fileState.name }}</div>
                      <div>{{ field._fileState.status }} {{ field._fileState.progress }}</div>
                    </div>
                  </button>
                </p>
              </template>
              <template v-else-if="editData[field.key]">
                <b-field expanded>
                  <button class="button" @click.prevent="$emit('file-delete', field)"><fa icon="times" /></button>
                  <p class="control value pad-left-10 pointer" @click="$emit('file-click', editData[field.key])">{{ getValue(field) }}</p>
                </b-field>
              </template>
              <template v-else>
                <div class="file" :class="field._class">
                  <label class="file-label">
                    <input class="file-input" type="file" @change="fileChanged($event, field)" name="resume" :accept="field.accept" />
                    <span class="file-cta">
                      <span class="file-icon"><i class="fas fa-upload"></i></span>
                      <span class="file-label">Choose a file…</span>
                    </span>
                  </label>
                </div>
              </template>
            </template>

            <!-- BUTTON -->
            <template v-else-if="field.input === 'button'">
              <p class="control buttons">
                <button class="button"
                  v-for="(buttonText, index) in field.buttonText"
                  :key="index"
                  :class="field.buttonClass"
                  @click.stop.prevent="$emit('button-click', {field, editData, index, buttonText })"
                >
                  {{ buttonText }}
                </button>
              </p>
            </template>

            <!-- AUTOCOMPLETE -->
            <template v-else-if="field.input === 'autocomplete'">
              <b-autocomplete
                v-model="editData[field.key]"
                :placeholder="getPlaceholder(field)"
                :class="field._class"
                :keep-first="true"
                :open-on-focus="true"
                :clear-on-select="false"
                :data="field._filteredOptions"
                field="title"
                @input="onInput(field)"
                @select="option => editData[field.key] = option ? option.value : editData[field.key]">
              </b-autocomplete>
            </template>

            <template v-else-if="field.input === 'readonly'">
              <p class="control value">{{ editData[field.key] }}</p>
            </template>

            <!-- TEXT, ESLE -->
            <template v-else-if="field.input === 'number'">
              <input
                :type="field.input"
                class="input"
                :class="field._class"
                :placeholder="getPlaceholder(field)"
                v-model="editData[field.key]"
                :min="typeof field.min == 'number' ? field.min : ''"
                :max="typeof field.max == 'number' ? field.max : ''"
                @focus="onFocus(field)"
                @input="onInput(field)"
              />
            </template>

            <!-- TEXT, ESLE -->
            <template v-else>
              <input
                :type="field.input ? field.input : 'text'"
                class="input"
                :class="field._class"
                :placeholder="getPlaceholder(field)"
                v-model="editData[field.key]"
                @focus="onFocus(field)"
                @input="onInput(field)"
              />
            </template>

            <!-- MESSAGE -->
            <p v-for="(msg, index) in field._messages" :key="index" class="help" :class="field._class">{{ msg }}</p>

          </template> <!-- v-if editing -->

        </div>

        <div v-if="field.content" v-html="field.content" :class="field.contentClass || ''"></div>

      </div>

    </template>

    <div v-if="showSubmit || showCancel || showDelete" :class="buttonsClass">
      <div class="buttons flex">
        <div class="flex-1">
          <button v-if="showSubmit" class="button is-info" type="submit" @click.prevent="submit()">{{ buttonSubmitLabel }}</button>
          <button v-if="showCancel" class="button" @click.prevent="$emit('cancel')">{{ buttonCancelLabel }}</button>
        </div>
        <button v-if="showDelete" class="button is-danger" @click.prevent="$emit('delete')">{{ buttonDeleteLabel }}</button>
      </div>
    </div>

  </form>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    fields: { requried: true },
    data: { default: null },
    editing: { default: true },
    autocomplete: { default: 'on' },
    validateRequired: { default: true },
    showSubmit: { default: false },
    showCancel: { default: false },
    showDelete: { default: false },
    showRequiredAstrix: { default: true },
    requiredDefault: { default: false },
    containerClass: { default: 'columns is-multiline' },
    fieldClass: { default: 'column is-6' },
    buttonsClass: { default: 'column is-12' },
    buttonSubmitLabel: { default: 'Submit' },
    buttonCancelLabel: { default: 'Cancel' },
    buttonDeleteLabel: { default: 'Delete' },
    hideLabels: { default: false }
  },
  data () {
    return {
      editData: null,
      submitPressed: false,
      dates: {}
    }
  },
  created () {
    this.prepareData()
  },
  watch: {
    fields () { this.prepareData() },
    data () { this.prepareData() },
    editing () {
      if (this.editing) {
        this.prepareData()
      }
    }
  },
  methods: {
    showField (field) {
      if (!field.key && !this.editing && !field.content && !field.value && !field.class) return false
      if (field.show === undefined) return true
      else if (typeof field.show === 'boolean') return field.show
      else if (typeof field.show === 'function') return field.show(this.editData)
      else return true
    },
    getTitle (field, noAsterix = false) {
      let title = null
      if (field.title) {
        title = field.title
      } else if (field.key) {
        title = field.key.trim().replace(/([A-Z])/g, ' $1').replace(/^./, str => { return str.toUpperCase() })
      }
      if (field.prefix) {
        title = field.prefix + title
      }
      if (field.postfix) {
        title += field.postfix
      }
      if (this.editing && this.showRequiredAstrix && this.isRequired(field) && !noAsterix) {
        title += ' *'
      }
      return title
    },
    getValue (field) {
      let value = '-'
      if (!field.key) {
        if (typeof field.value === 'function') {
          const data = this.editing ? this.editData : this.data
          value = field.value(data)
        } else {
          value = ''
        }
      } else if (this.data) {
        if (field.input === 'select' || field.input === 'radio') {
          const i = field._options.findIndex(o => o.value === this.data[field.key])
          if (i >= 0) {
            value = field._options[i].title
          }
        } else if (field.input === 'date' && this.data[field.key]) {
          const dataFormat = field.dateFormat || 'DD/MM/YYYY'
          const obj = this.data[field.key]
          if (obj instanceof Date) {
            value = moment(obj).format(dataFormat)
          } else if (typeof obj.toDate === 'function') {
            value = moment(obj.toDate()).format(dataFormat)
          } else if (obj.seconds) {
            value = moment.unix(obj.seconds).format(dataFormat)
          }
        } else if (field.input === 'file') {
          const fileObj = this.data[field.key]
          if (fileObj) {
            value = fileObj.name
          }
        } else if (this.data[field.key] !== undefined && `${this.data[field.key]}`.length) {
          value = this.data[field.key]
        }
      }
      return value
    },
    onFocus (field) {
      if (typeof field.onFocus === 'function') {
        this.editData[field.key] = field.onFocus(this.editData[field.key])
      }
    },
    onInput (field) {
      if (this.submitPressed) {
        this.validateField(field)
      }
      if (typeof field.onInput === 'function') {
        this.editData[field.key] = field.onInput(this.editData[field.key], this.editData)
      }
      if (typeof field.onChange === 'function') {
        field.onChange(field.key, this.editData)
      }
      if (field.input === 'autocomplete') {
        const str = this.editData[field.key].toLowerCase()
        field._filteredOptions = field._options.filter(option => option.title.toLowerCase().indexOf(str) >= 0)
      }
      this.$emit('input', this.editData, field.key)
    },
    fileChanged (event, field) {
      this.$set(field, '_file', event.target.files[0])
      this.$emit('file-select', field)
    },
    isRequired (field) {
      if (!this.validateRequired) {
        return false
      }
      if (this.requiredDefault) {
        return field.default !== false
      } else {
        return field.required === true
      }
    },
    getPlaceholder (field) {
      return field.placeholder ? field.placeholder : this.getTitle(field, true)
    },
    validate () {
      let valid = true
      for (const field of this.fields) {
        if (!this.validateField(field)) {
          valid = false
        }
      }
      return valid
    },
    prepareSubmit () {
      let arrayifyKeys = null
      for (const field of this.fields) {
        if (!field.key) {
          if (field.arrayify) {
            arrayifyKeys = field.arrayify
          }
          continue
        }
        if (field.input === 'number' || field.type === 'number') {
          if (!`${this.editData[field.key]}`.trim().length) {
            this.editData[field.key] = ''
          } else {
            this.editData[field.key] = Number(this.editData[field.key])
          }
        } else if (field.input === 'checkbox') {
          this.editData[field.key] = this.editData[field.key] === 'true' || this.editData[field.key] === true
        } else if (field.input === 'date') {
          this.editData[field.key] = this.dates[field.key]
        } else if (field.input === 'file') {
          // prepare file ?
        } else {
          this.editData[field.key] = `${this.editData[field.key]}`.trim()
        }
      }
      if (arrayifyKeys) {
        this.$utils.arrayifyValues(this.editData, arrayifyKeys)
      }
      return this.editData
    },
    submit (validate = true) {
      this.submitPressed = true
      this.prepareSubmit()
      if (!this.validate() && validate) {
        return false
      }

      this.$emit('submitted', this.editData)
      return this.editData
    },
    validateField (field) {
      if (!field.key || !this.showField(field)) return true
      let valid = true
      field._messages.splice(0)
      field._class = ''
      let value = this.editData[field.key]
      if (field.input === 'date') {
        value = this.dates[field.key]
      }

      if (this.isRequired(field)) { // If required make sure something is entered
        if (value === null || (field.input === 'checkbox' && !value) || !`${value}`.trim().length) {
          field._messages.push(`${this.getTitle(field)} is required`)
          valid = false
        }
      }

      if (valid) {
        if (typeof field.validate === 'function') { // If there is a custmot validate function user that.
          const msg = field.validate(value, this.editData)
          if (msg !== true) {
            field._messages.push(msg)
            valid = false
          }
        } else {
          if (field.chars && `${value}`.trim().length > 0 && `${value}`.trim().length !== field.chars) {
            field._messages.push(`${this.getTitle(field)} requires ${field.chars} ${field.input === 'number' ? 'numbers' : 'charactes'}`)
            valid = false
          }
          if (value && value.length && field.input === 'email') {
            const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (!regEx.test(String(value).toLowerCase())) {
              field._messages.push('Invalid email address')
              valid = false
            }
          }
        }
      }

      if (!valid) {
        field._class = 'is-danger'
      }
      return valid
    },
    prepareData () {
      this.submitPressed = false
      const blank = {}
      for (const i in this.fields) {
        const field = this.fields[i]
        if (!field.key) {
          if (field.input === 'button') {
            if (typeof field.buttonText === 'string') {
              field.buttonText = [field.buttonText]
            }
          }
          continue
        }
        this.$set(this.fields[i], '_messages', [])
        this.$set(this.fields[i], '_class', '')
        if (field.default !== undefined) {
          blank[field.key] = field.default
        } else if (field.input === 'date') {
          blank[field.key] = field.default === 'today' ? new Date() : null
          this.$set(this.dates, field.key, null)
          if (this.data && this.data[field.key]) {
            const obj = this.data[field.key]
            if (typeof obj.toDate === 'function') {
              this.dates[field.key] = obj.toDate()
            } else if (obj.seconds) {
              this.dates[field.key] = moment.unix(obj.seconds).toDate()
            }
          }
        } else if (field.input === 'checkbox') {
          blank[field.key] = false
        } else if (field.input === 'file') {
          this.$set(this.fields[i], '_fileState', { status: 'none', progress: '', name: '' })
          blank[field.key] = null
        } else {
          blank[field.key] = ''
        }
        if (field.input === 'select' || field.input === 'radio' || field.input === 'autocomplete') {
          this.$set(this.fields[i], '_options', this.prepareFieldOptions(field.options))
        }
        if (field.input === 'autocomplete') {
          this.$set(this.fields[i], '_filteredOptions', this.fields[i]._options)
        }
      }
      this.editData = Object.assign(blank, this.data || {})
      return this.editData
    },
    prepareFieldOptions (options) {
      const _options = []
      for (const option of options) {
        _options.push({
          title: typeof option === 'object' ? option.title : option,
          value: typeof option === 'object' ? option.value : option
        })
      }
      return _options
    },
    updateValue (key, value) {
      this.$set(this.editData, key, value)
    },
    updateFieldOptions (key, options) {
      const i = this.fields.findIndex(f => f.key === key)
      if (i >= 0) {
        this.$set(this.fields[i], 'options', options)
        this.$set(this.fields[i], '_options', this.prepareFieldOptions(options))
      }
    },
    setValue (key, value) {
      this.editData[key] = value
    }

  }
}
</script>

<style>
  .sdf-form .is-danger input {
    border-color: #ff3860;
  }

  .sdf-form .control.value {
    padding-bottom: calc(0.375em - 1px);
    /* padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px); */
    padding-top: calc(0.375em - 1px);
  }

  .sdf-form .input-group {
    background-color: rgb(247, 247, 247);
  }

  .sdf-form .form-spacer {
    margin: 10px 0;
    /* padding-bottom: 10px; */
  }

  .sdf-form .form-spacer.hr {
    border-top: solid 2px #eee;
  }

  .sdf-form .uppercase-value .value,
  .sdf-form .uc-value .value {
    text-transform: uppercase;
  }

</style>
