<template>
  <div class="raster-maps height-100">

    <edit-raster-map-modal :id="editId" :show.sync="showEdit" />

    <div v-if="loading" class="height-100 flex-middle font-size-200 has-text-grey-light">
      <div>
        <fa icon="spinner" size="2x" pulse />
      </div>
    </div>

    <div v-else class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name">
          Raster Maps
        </div>
        <div v-if="!processingSelected" class="pad-left">
          <button class="button has-text-grey" @click="fetchMaps()"><fa icon="sync-alt" /></button>
        </div>
        <div class="flex-1"></div>
        <div v-if="!processingSelected" class="buttons">
          <button class="button" :disabled="!selectedItems.length" @click="processingSelected = true">process {{ selectedItems.length > 1 ? selectedItems.length : '' }} Selected Map{{ !selectedItems.length || selectedItems.length > 1 ? 's' : '' }}</button>
          <router-link class="button is-info" to="/raster-maps/add">Add Maps</router-link>
        </div>
        <template v-if="processingSelected">
          <div class="name color-666 uc pad-right">
            {{ selectedItems.length }} SELECTED MAP{{ selectedItems.length > 1 ? 'S' : '' }}:
          </div>
          <div class="buttons">
            <button class="button" @click="processingSelected = false">CANCEL</button>
            <button class="button" @click="createMapSet()">CREATE MAP SET</button>
            <button class="button is-danger" @click="deleteSelectedMaps()">DELETE</button>
          </div>
        </template>
      </div>

      <div v-if="processingSelected" class="flex-table-header">
        <div class="flex-table-cell flex-0 w-60px center"></div>
        <div class="flex-table-cell flex-2">Publisher</div>
        <div class="flex-table-cell flex-4">Name</div>
        <div class="flex-table-cell flex-2">Description</div>
        <div class="flex-table-cell flex-1">Min Z</div>
        <div class="flex-table-cell flex-1">Max Z</div>
      </div>

      <div v-else class="flex-table-header">
        <div class="flex-table-cell flex-0 w-60px center">
          <button class="button is-small" @click="toggleAll()"><fa icon="check" /></button>
        </div>
        <div class="flex-table-cell flex-2"><b-input v-model="searchStrings.publisher" size="is-small" placeholder="Publisher" /></div>
        <div class="flex-table-cell flex-4"><b-input v-model="searchStrings.name" size="is-small" placeholder="Name" /></div>
        <div class="flex-table-cell flex-2"><b-input v-model="searchStrings.description" size="is-small" placeholder="Description" /></div>
        <div class="flex-table-cell flex-1"><b-input v-model="searchStrings.minZ" size="is-small" placeholder="Min Z" /></div>
        <div class="flex-table-cell flex-1"><b-input v-model="searchStrings.maxZ" size="is-small" placeholder="Max Z" /></div>
        <div class="flex-table-cell flex-0 w-60px"></div>
      </div>

      <div v-if="items" class="flex-table-body">
        <div v-for="item in filteredItems" :key="item._id" class="flex-table-row" :class="{selected: item.selected}">
          <div class="flex-table-cell flex-0 pointer w-60px center has-text-success" @click="toggleSelect(item)"><fa v-if="item.selected" icon="check" /></div>
          <div class="flex-table-cell flex-2 pointer" @click="toggleSelect(item)">{{ item.publisher }}</div>
          <div class="flex-table-cell flex-4 pointer" @click="toggleSelect(item)">{{ item.name }}</div>
          <div class="flex-table-cell flex-2 pointer" @click="toggleSelect(item)">{{ item.description }}</div>
          <div class="flex-table-cell flex-1 pointer" @click="toggleSelect(item)">{{ item.minZ }}</div>
          <div class="flex-table-cell flex-1 pointer" @click="toggleSelect(item)">{{ item.maxZ }}</div>
          <div class="flex-table-cell flex-0 w-60px" @click="editMap(item._id)">
            <button class="button is-small"><fa icon="edit" /></button>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import EditRasterMapModal from '@/components/EditRasterMapModal.vue'
export default {
  components: { EditRasterMapModal },
  data () {
    return {
      loading: false,
      items: null,
      processingSelected: false,
      newMapSetId: null,
      batching: false,
      searchStrings: {
        publisher: '',
        name: '',
        description: '',
        minZ: '',
        maxZ: ''
      },
      editId: null,
      showEdit: false
    }
  },
  computed: {
    user () { return this.$store.getters.user },
    filteredItems () {
      if (this.processingSelected) return this.selectedItems
      const searchStrings = {}
      for (const k in this.searchStrings) {
        searchStrings[k] = `${this.searchStrings[k]}`.trim().toLocaleLowerCase()
      }
      return this.items.filter(item => {
        for (const k in searchStrings) {
          const str = searchStrings[k]
          if (str.length && `${item[k]}`.trim().toLocaleLowerCase().indexOf(str) < 0) return false
        }
        return true
      })
    },
    selectedItems () {
      return this.items.filter(item => item.selected)
    }
  },
  created () {
    this.fetchMaps()
  },
  methods: {
    editMap (id) {
      this.editId = id
      this.showEdit = true
    },
    async fetchMaps () {
      this.loading = 'FETCHING RASTER MAPS'
      const ss = await this.$fs.collection('rasterMaps')
        .orderBy('publisher')
        .orderBy('name')
        .get()
      this.items = this.$ssToArr(ss).map(item => {
        item.selected = false
        return item
      })
      this.loading = false
    },
    toggleSelect (item) {
      item.selected = !item.selected
    },
    toggleAll () {
      let somethingSelected = false
      for (const item of this.filteredItems) {
        if (item.selected) {
          somethingSelected = true
          break
        }
      }
      for (const item of this.filteredItems) {
        item.selected = !somethingSelected
      }
    },
    async createMapSet () {
      const name = await this.$prompt({
        title: 'Create Map Set',
        message: 'Enter a name for the new map set',
        inputAttrs: {
          placeholder: 'Map set name'
        }
      })
      if (name === null) return
      this.batchOperation = 'createMapSet'
      try {
        const resp = await this.$fs.collection('rasterMapSets').add({
          name,
          userEmail: this.user.email,
          mapIds: this.selectedItems.map(item => item._id),
          maps: this.selectedItems,
          timestampCreated: this.$moment().unix()
        })
        this.newMapSetId = resp.id
        await this.processSelectedMaps()
        this.$toast.open({
          type: 'is-success',
          message: `Map set <b>${name}</b> created with ${this.selectedItems.length} map${this.selectedItems.length > 1 ? 's' : ''}`
        })
        this.$router.push(`/raster-maps/sets/${this.newMapSetId}`)
      } catch (error) {
        this.$showError(error)
      }
      this.batchOperation = null
    },
    async deleteSelectedMaps () {
      const confirmed = await this.$confirm({
        type: 'is-danger',
        title: 'Deleting Map',
        message: `Are you sure you want to delete ${this.selectedItems.length} map${this.selectedItems.length > 1 ? 's' : ''}`
      })
      if (!confirmed) return
      try {
        this.batchOperation = 'delete'
        await this.processSelectedMaps()
        this.$toast.open({
          type: 'is-danger',
          message: `${this.selectedItems.length} map${this.selectedItems.length > 1 ? 's' : ''} <b>DELETED</b>`
        })
        await this.fetchMaps()
        this.processingSelected = false
      } catch (error) {
        this.$showError(error)
      }
      this.batchOperation = null
    },
    async processSelectedMaps () {
      let batch = this.$fs.batch()
      let count = 0
      for (const item of this.selectedItems) {
        if (count >= 500) {
          await batch.commit()
          batch = this.$fs.batch()
          count = 0
        }
        const doc = this.$fs.doc(`/rasterMaps/${item._id}`)
        if (this.batchOperation === 'delete') {
          batch.delete(doc)
        } else if (this.batchOperation === 'createMapSet' && this.newMapSetId) {
          const mapSets = item.mapSets || []
          batch.update(doc, { mapSets: [...mapSets, this.newMapSetId] })
        }
        count++
      }
      await batch.commit()
    }
  }
}
</script>

<style lang="scss">
.raster-maps {
  .flex-table-row.selected:nth-child(2n) {
    background-color: rgba(0, 255, 0, 0.3) !important;
  }
  .flex-table-row.selected {
    background-color: rgba(0, 255, 0, 0.25) !important;
  }
}
</style>
