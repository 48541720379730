<template>
  <div class="map-style-editor">

    <b-modal :active.sync="showGetJsonModal">
      <div class="box flex-col" style="width: 100%; max-width: 90vw;">
        <div class="flex">
          <div class="flex-1 bold font-size-200">Get Style JSON</div>
          <div><b-switch v-model="escapeJsonStr">Escape String</b-switch></div>
        </div>
        <div class="flex-1 flex-middle">
          <fa v-if="getJsonStrValue === null" icon="spinner" size="5x" style="opacity: 0.2" pulse />
          <textarea ref="jsonStrEl" v-if="getJsonStrValue" class="height-100 width-100" v-model="getJsonStrValue"></textarea>
        </div>
        <div class="buttons margin-top">
          <button class="button" :disabled="!getJsonStrValue" @click="CopyJsonStr()">Copy</button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="showSetJsonModal" :can-cancel="!updating">
      <div class="box flex-col" style="width: 100%; max-width: 90vw;">
        <div class="flex">
          <div class="flex-1 bold font-size-200">Set Style JSON (NOT ESCAPED)</div>
        </div>
        <div class="flex-1 flex-middle">
          <textarea class="height-100 width-100" v-model="setJsonStrValue"></textarea>
        </div>
        <div class="buttons margin-top">
          <button class="button is-info" :class="{ 'is-loading': updating }" :disabled="!setJsonStrValue" @click="setJsonStr()">Set</button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="extrapolate.showModal">
      <div class="box" style="max-width: 500px; margin: 0 auto;">
        <div class="bold font-size-120 pad-bottom">Extrapolate Widths</div>
        <div class="flex">
          <div class="pad-sides">[</div>
          <div class="flex-1"><b-input @input="extrapolateWidths()" size="is-small" type="number" v-model="extrapolate.minZ" /></div>
          <div class="pad-sides">,</div>
          <div class="flex-1"><b-input @input="extrapolateWidths()" size="is-small" type="number" v-model="extrapolate.minW" /></div>
          <div class="pad-sides">]</div>
          <div class="pad-sides">,</div>
          <div class="pad-sides">[</div>
          <div class="flex-1"><b-input @input="extrapolateWidths()" size="is-small" type="number" v-model="extrapolate.maxZ" /></div>
          <div class="pad-sides">,</div>
          <div class="flex-1"><b-input @input="extrapolateWidths()" size="is-small" type="number" v-model="extrapolate.maxW" /></div>
          <div class="pad-sides">]</div>
        </div>
        <div class="flex">
          <div class="flex-1 center">Z{{ minZ }}: {{ extrapolate.extrapolatedMinW }}</div>
          <div class="flex-1 center">Z{{ maxZ }}: {{ extrapolate.extrapolatedMaxW }}</div>
        </div>
        <div class="buttons pad-top">
          <button class="button is-small" @click="extrapolate.showModal = false">Close</button>
          <button class="button is-small is-info">Update</button>
        </div>
      </div>
    </b-modal>

    <!-- LAYERS -->
    <div class="side side-spacer pad-top-10">
      <div class="nav-spacer"></div>
      <div class="bold pad-5">LAYERS</div>
      <div
        class="layer pointer pad-5 margin-5"
        :class="{'bg-ccc': layerKey === key}"
        v-for="(layer, key) in layers" :key="key"
        @click="onLayerSelect(key)"
      >
        <div>[{{layer.i}}] {{layer.name}}</div>
        <div class="font-size-80" style="margin-top: -5px;">{{ layer.type }}</div>
      </div>
    </div>

    <!-- MENU BAR -->
    <div class="toolbar flex">
      <div class="name flex-1">
        Map Style >
        <span class="has-text-grey">{{ styleId }}</span>
      </div>
      <div class="buttons">
        <button class="button" @click="$back()">Back</button>
        <b-dropdown :triggers="['hover']" aria-role="list" position="is-bottom-left">
          <template #trigger>
            <button class="button"><fa icon="caret-down" /></button>
          </template>
          <b-dropdown-item aria-role="listitem" @click="showGetJsonModal = true">Get Json String</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="setJsonStrValue = ''; showSetJsonModal = true">Set Json String</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="nav-spacer"></div>

    <!-- STYLES -->
    <div class="main pad-top-10">
      <div class="flex">
        <div class="side-spacer flex-0"></div>
        <div class="flex-1 pad-10">

          <!-- <div class="border-000" :style="dashedLine(3, 10, 5, 'blue')">
            TESTING DASHES<br />
            new line
          </div> -->

          <template v-if="!layerKey">
            SELECT A LAYER
          </template>
          <template v-else>
            <b-collapse
              class="card margin-bottom"
              animation="slide"
              v-for="(style, i) in styles"
              :key="style.name"
              :open="styleIndex === i"
              @open="onStyleSelect(i)">
              <div
                slot="trigger"
                slot-scope="props"
                class="card-header"
                role="button">
                <p class="card-header-title flex">
                  <span>[{{ style.i }}]</span>
                  <span class="margin-left-5 flex-0 w-80px border center" :class="{'no-color': !style.color}" :style="{backgroundColor: style.color || 'none'}">
                    {{ style.color ? '' : '?'}}
                    <span v-if="style.color" class="invisible">-</span>
                  </span>
                  <span class="span pad-left">
                    <fa v-if="layerType === 'polygon'" icon="draw-polygon" />
                    <span v-if="layerType === 'line'">-</span>
                  </span>
                  <span class="pad-left flex-0 w-200px pad-right-20">{{ style.name }}</span>

                  <span class="flex-1 flex" :style="{backgroundColor: bgColor}" v-if="layerType === 'line'">
                    <span class="flex-1 height-100" :style="dashedLine(style.minWidth, style.dashLength, style.dashGap, style.color, style.a)">
                      <span class="invisible">line</span>
                    </span>
                    <span class="flex-1 height-100" :style="dashedLine(style.maxWidth, style.dashLength, style.dashGap, style.color, style.a)">
                      <span class="invisible">line</span>
                    </span>
                  </span>

                </p>
                <a class="card-header-icon">
                  <fa :icon="props.open ? 'times' : 'edit'" />
                </a>
              </div>
              <div class="card-content pad-10">
                <div class="content flex">
                  <!-- COLOR -->
                  <div class="w-100px flex-col">
                    <div class="bold center">CURRENT</div>
                    <div class="flex-1" :class="{'no-color': !style.color}" :style="{backgroundColor: style.color || 'none', opacity: style.a || 1}"></div>
                  </div>
                  <div class="w-100px flex-col">
                    <div class="bold center">NEW</div>
                    <div v-if="editColor" class="flex-1" :style="{backgroundColor: editColor.hex || 'none', opacity: editColor.a || 1}"></div>
                  </div>
                  <div class="flex-col margin-left">
                    <div class="bold invisible">-</div>
                    <color-picker @input="updateColor" :value="style.color || '#FFFFFF'" :preset-colors="[]" />
                  </div>

                  <div class="flex-1 flex-col pad-sides">
                    <!-- LINE WIDTH -->
                    <template v-if="layerType === 'line'">
                      <!-- <div class="bold">LINE WIDTH</div> -->
                      <div class="flex-1 flex-col pad-top">
                        <div class="flex margin-bottom-5">
                          <div class="flex-1 flex-middle">
                            WIDTH AT Z <b>{{ style.minZ }}</b>
                            <b-input type="number" min="1" size="is-small" v-model="minWidth" class="w-50px pad-left-5" />
                          </div>
                          <div class="pointer" @click="showExtrapolate()"><fa icon="cog" /></div>
                          <div class="flex-1 flex-middle">
                            WIDTH AT Z <b>{{ maxZ }}</b>
                            <b-input type="number" size="is-small" v-model="maxWidth" class="w-50px pad-left-5" />
                          </div>
                        </div>
                        <div class="flex-middle">
                          <b>DASH</b>
                          <span class="pad-sides">LENGTH</span> <b-input type="number" size="is-small" v-model="dashLength" class="w-50px" />
                          <span class="pad-sides">GAP</span> <b-input type="number" size="is-small" v-model="dashGap" class="w-50px" />
                        </div>
                        <div class="flex-1 width-100 flex-col">
                          <div class="center font-size-80 bold">NEW WIDTHS</div>
                          <div class="flex-1 flex-middle border-000" :style="{backgroundColor: bgColor}" v-if="editColor">
                            <div class="flex-1 height-100" :style="dashedLine(minWidth, dashLength, dashGap, editColor.hex, editColor.a)"></div>
                            <div class="flex-1 height-100" :style="dashedLine(maxWidth, dashLength, dashGap, editColor.hex, editColor.a)"></div>
                            <!-- <div class="flex-1" :style="{backgroundColor: editColor.hex || 'none', opacity: editColor.a || 1, height: `${minWidth}px`}"></div> -->
                            <!-- <div class="flex-1" :style="{backgroundColor: editColor.hex || 'none', opacity: editColor.a || 1, height: `${maxWidth}px`}"></div> -->
                          </div>
                        </div>
                      </div>
                      <div class="flex-1 flex-col pad-top">
                        <div class="flex-1 width-100 flex-col">
                          <div class="center font-size-80 bold">CURRENT WIDTHS</div>
                          <div class="flex-1 flex-middle border-000" :style="{backgroundColor: bgColor}" v-if="editColor">
                            <div class="flex-1 height-100" :style="dashedLine(style.minWidth, style.dashLength, style.dashGap, style.color, style.a)"></div>
                            <div class="flex-1 height-100" :style="dashedLine(style.maxWidth, style.dashLength, style.dashGap, style.color, style.a)"></div>
                            <!-- <div class="flex-1" v-if="editColor" :style="{backgroundColor: style.color || 'none', opacity: editColor.a || 1, height: `${style.minWidth || 1}px`}"></div>
                            <div class="flex-1" v-if="editColor" :style="{backgroundColor: style.color || 'none', opacity: editColor.a || 1, height: `${style.maxWidth || 1}px`}"></div> -->
                          </div>
                        </div>
                        <div class="flex bold margin-bottom-5 invisible">-</div>
                      </div>
                    </template>
                  </div>
                  <div class="flex-col">
                    <div class="flex-1"></div>
                    <div class="buttons">
                      <button class="button is-success" @click="onUpdate()">UPDATE</button>
                      <button class="button" @click="styleIndex = -1"><fa icon="times" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </template>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { layers, maxZ } from './layers/layers'
import { Chrome } from 'vue-color'
export default {
  components: {
    'color-picker': Chrome
  },
  data () {
    return {
      getJsonStrValue: '',
      setJsonStrValue: '',
      escapeJsonStr: true,
      showGetJsonModal: false,
      showSetJsonModal: false,
      extrapolate: {
        showModal: false,
        minZ: null,
        minW: null,
        maxZ: null,
        maxW: null,
        extrapolatedMinW: null,
        extrapolatedMaxW: null
      },
      maxZ,
      layers,
      layerKey: null,
      styleIndex: -1,
      styleData: {},
      minZ: null,
      minWidth: null,
      maxWidth: null,
      dashLength: 0,
      dashGap: 0,
      editColor: null,
      defaultPolygon: { color: null },
      updating: false,
      defaultLine: { color: null, minWidth: null, maxWith: null }
    }
  },
  computed: {
    styleId () { return this.$route.params.id || null },
    bgColor () { return this.styleData ? this.styleData.background.styles[0].color || 'none' : 'none' },
    layerType () { return this.layerKey ? this.styleData[this.layerKey].type : null },
    styles () {
      if (!this.styleData || !this.layerKey) return []
      return this.styleData[this.layerKey].styles
    },
    selectedStyle () {
      return this.styles[this.styleIndex] || null
    }
  },
  watch: {
    styleId () { this.fetchStyle() },
    escapeJsonStr () { this.updateJsonStrValue() }
  },
  created () {
    this.fetchStyle()
  },
  methods: {
    async setJsonStr () {
      const confirmed = await this.$confirm({
        type: 'is-danger',
        title: 'Setting JSON style',
        message: 'Are you sure you want to set the style json? This will overwrite all the current styles.'
      })
      if (!confirmed) return
      this.updating = true
      try {
        const updateData = JSON.parse(this.setJsonStrValue)
        updateData.timestamp = this.$moment().unix()
        updateData._id = this.styleId
        updateData.name = this.styleId
        await this.$fs.doc(`styles/${this.styleData._id}`).update(updateData)
        this.showSetJsonModal = false
        this.setJsonStrValue = ''
      } catch (error) {
        this.$showError(error)
      }
      this.updating = false
    },
    CopyJsonStr () {
      const copyText = this.$refs.jsonStrEl
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open('copied to clipboard')
    },
    updateJsonStrValue () {
      this.getJsonStrValue = this.styleData ? JSON.stringify(this.styleData) : null
      if (this.getJsonStrValue && this.escapeJsonStr) {
        let str = JSON.stringify(String(this.getJsonStrValue))
        str = str.substring(1, str.length - 1)
        this.getJsonStrValue = str
      }
    },
    dashedLine (width, length, gap, color, a = 1) {
      width = Number(width)
      length = Number(length)
      gap = Number(gap)
      if (!length || length <= 0) {
        length = 10
        gap = 0
      }
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${length + gap}" height="${width}"><rect fill="${color}" stroke="none" x="0" y="0" width="${length}" height="${width}" /></svg>`
      const style = {
        opacity: a,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'center center',
        backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`
      }
      return style
    },
    showExtrapolate () {
      this.extrapolate.minZ = null
      this.extrapolate.minW = null
      this.extrapolate.maxZ = null
      this.extrapolate.maxW = null
      this.extrapolate.extrapolatedMinW = '-'
      this.extrapolate.extrapolatedMaxW = '-'
      this.extrapolate.showModal = true
    },
    extrapolateWidths (z) {
      this.extrapolate.extrapolatedMinW = this.extrapolateWidth(this.minZ)
      this.extrapolate.extrapolatedMaxW = this.extrapolateWidth(this.maxZ)
    },
    extrapolateWidth (z) {
      if (this.extrapolate.minZ === null ||
      this.extrapolate.minW === null ||
      this.extrapolate.maxZ === null ||
      this.extrapolate.maxW === null) return '-'

      const minZ = Number(this.extrapolate.minZ)
      const minW = Number(this.extrapolate.minW)
      const maxZ = Number(this.extrapolate.maxZ)
      const maxW = Number(this.extrapolate.maxW)

      const dZ = maxZ - minZ
      const dW = maxW - minW

      const progress = z - minZ
      const normalised = progress / dZ
      const w = minW + (normalised * dW)
      return Math.round(w * 10) / 10
    },
    fetchStyle () {
      this.unsub('style')
      if (!this.styleId) {
        return
      }
      this.subs.style = this.$fs.doc(`styles/${this.styleId}`)
        .onSnapshot(ss => {
          this.styleData = this.$ssToObj(ss)
          this.updateJsonStrValue()
        })
    },
    onLayerSelect (layerKey) {
      if (this.layerKey === layerKey) return
      this.layerKey = layerKey
      this.styleIndex = -1
    },
    onStyleSelect (i) {
      this.styleIndex = i
      const style = this.styles[i]
      this.minZ = style.minZ
      this.minWidth = Number(style.minWidth) || 1
      this.maxWidth = Number(style.maxWidth) || 1
      this.dashLength = style.dashLength || 0
      this.dashGap = style.dashGap || 0
      this.editColor = {
        hex: style.color || '#FFFFFF',
        a: style.a || 1
      }
    },
    async onUpdate () {
      this.updating = true
      if (!this.selectedStyle) return
      try {
        const styles = JSON.parse(JSON.stringify(this.styles))
        styles[this.styleIndex].color = this.editColor.hex
        styles[this.styleIndex].a = Number(this.editColor.a)
        if (this.layerType === 'line') {
          styles[this.styleIndex].minWidth = Number(this.minWidth)
          styles[this.styleIndex].maxWidth = Number(this.maxWidth)
          styles[this.styleIndex].dashLength = Number(this.dashLength)
          styles[this.styleIndex].dashGap = Number(this.dashGap)
        }
        const updateData = { timestamp: this.$moment().unix() }
        updateData[`${this.layerKey}.styles`] = styles
        await this.$fs.doc(`styles/${this.styleData._id}`)
          .update(updateData)
      } catch (err) {
        this.$alert({ type: 'is-danger', title: 'ERROR', message: `<p>Could not update style</p><b>${err.message}</b>` })
      }
      this.updating = false
    },
    updateColor (color) {
      this.editColor = color
    }
  }
}
</script>

<style lang="scss">
.map-style-editor {
  a { color: #333; }

  .toolbar {
    position: fixed;
    z-index: 1;
  }

  .main {
    padding-top: 70px;
  }

  .side {
    position: fixed;
    height: 100vh;
    background-color: #eee;
    margin-top: 61px;
  }

  // .nav-spacer { height: 52px; }
  .side-spacer { width: 150px; }
  // .nav-margin { margin-top: 52px; }

  .side .layer:hover {
    background-color: #ddd;
  }

  .vc-sketch {
    box-shadow: none !important;
    /* border: solid 1px #eee; */
  }
  .vc-sketch-presets { display: none; }

  .vc-sketch { padding: 0 !important; }
  .vc-chrome { box-shadow: none !important; border: solid 1px #ccc; }
  .no-color {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC");
  }
}
</style>
