<template>
  <div class="edit-raster-map-modal">

    <b-modal :active.sync="showModal" @close="close()" :can-cancel="!loading">
      <div class="box flex-col">
        <div class="flex border-bottom-eee pad-bottom margin-bottom">
          <div class="bold font-size-120 flex-2">
            Edit Raster Map <fa v-if="loading" icon="spinner" pulse class="has-text-grey-light" />
            <p class="font-size-80">{{ data && data.name }}</p>
          </div>
          <b-checkbox class="margin-left" v-model="jsonMode">JSON mode</b-checkbox>
        </div>
        <div class="flex-1 margin-top margin-bottom">
          <template v-if="jsonMode">
            <textarea v-model="jsonStr" rows="7" class="input height-100"></textarea>
          </template>
          <template v-else>
            <sdf-form ref="form" :data="data" :fields="fields" />
          </template>
        </div>
        <div class="buttons flex margin-top">
          <button class="button is-small" @click="close()" :disabled="loading">
            <fa icon="times" class="margin-right-5" />
            Close
          </button>
          <button class="button is-small is-success" @click="update()" :disabled="loading" :class="{ 'is-loading': loading === 'update' }">
            <fa icon="check" class="margin-right-5" />
            Update
          </button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import SdfForm from '@/components/SdfForm'

const keys = ['fileName', 'url', 'bytes_total', 'checksum']
export default {
  components: { SdfForm },
  props: {
    id: { required: true },
    show: { required: true }
  },
  data: () => ({
    data: null,
    showModal: false,
    fields: [
      { key: 'fileName', required: true },
      { key: 'bytes_total', input: 'number', required: true },
      { key: 'url', required: true, class: 'column is-12' },
      { key: 'checksum', required: true, class: 'column is-12' }
    ],
    jsonStr: '',
    jsonMode: true,
    loading: null
  }),
  watch: {
    show () {
      this.showModal = this.show
      if (this.show) this.fetchMap()
    }
    // id () { this.fetchMap() }
  },
  methods: {
    async fetchMap () {
      this.loading = 'fetchMap'
      this.data = null
      if (!this.id) return
      try {
        const ss = await this.$fs.doc(`/rasterMaps/${this.id}`).get()
        this.data = this.$ssToObj(ss)
        const jsonObj = {}
        for (const key of keys) {
          if (this.data.key) {
            jsonObj[key] = this.data[key]
          }
        }
        this.jsonStr = Object.keys(jsonObj).length ? JSON.stringify(jsonObj) : ''
      } catch (error) {
        this.$showError(error)
      }
      this.loading = null
    },
    async update () {
      console.log('update')
      this.loading = 'update'
      try {
        let data = null
        if (this.jsonMode) {
          data = JSON.parse(this.jsonStr)
          data.fileName = data.fileName || data.file_name || ''
        } else {
          data = this.$refs.form.submit()
        }
        if (!data) return

        const errs = []
        for (const key of keys) {
          if (data[key] === undefined) {
            errs.push(key)
          }
        }
        if (errs.length) throw new Error('No ' + errs.join(', '))

        for (const key in data) {
          if (!keys.includes(key)) {
            delete data[key]
          }
        }
        console.log(data)
        const batch = this.$fs.batch()
        batch.update(this.$fs.doc(`/rasterMaps/${this.id}`), data)

        const ss = await this.$fs.collection('rasterMapSets')
          .where('mapIds', 'array-contains', this.id)
          .get()
        const mapSets = this.$ssToArr(ss) || []
        console.log(mapSets)
        for (const mapSet of mapSets) {
          const maps = mapSet.maps
          const mapIndex = maps.findIndex(m => m._id === this.id)
          if (mapIndex < 0) continue
          for (const key of keys) {
            maps[mapIndex][key] = data[key]
          }
          const docRef = this.$fs.doc(`/rasterMapSets/${mapSet._id}`)
          batch.update(docRef, { maps })
        }

        await batch.commit()

        this.jsonStr = ''
        this.close()
      } catch (error) {
        this.$showError(error)
      }
      this.loading = null
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss">
.edit-raster-map-modal {
  .modal-content {
    height: 90vh;
  }
  .modal-content .box {
    // height: 90% !important;
    max-height: calc(100vh - 50px);
  }
}
</style>
