<template>
  <div class="iap-products height-100">

    <b-modal :active.sync="showEditModal" :can-cancel="false">
      <div class="box flex-col bg-eee">
        <div class="bold font-size-120">{{ selectedProduct ? 'Edit' : 'Add' }} IAP Product</div>
        <div class="flex-1 margin-top-20 overflow-y-auto pad bg-fff">
          <!-- FORM -->
          <sdf-form
            ref="form"
            :fields="fields"
            :data="selectedProduct"
            :editing="true"
            @input="(data, key) => {
              if (key === 'type') {
                selectedIsRaster = data[key] === 'raster map'
              }
            }"
          />

          <div class="columns is-multiline">
            <div class="column is-12" v-if="selectedIsRaster">
              <b-field label="Raster Map Set">
                <b-select v-model="editMapSetId">
                  <option v-for="item in mapSets || []" :key="item._id" :value="item._id">{{ item.name }}</option>
                </b-select>
              </b-field>
            </div>

            <!-- IMAGES -->
            <div class="column is-12" v-for="(image, i) in editImages" :key="i">
              <b-field :label="`Image ${i + 1} URL`" grouped>
                <b-input v-model="image.url" expanded />
                <p class="control">
                  <button class="button" @click="editImages.splice(i, 1)"><fa icon="times" /></button>
                </p>
              </b-field>
            </div>
            <div class="column is-12 pad-top-0">
              <button class="button is-small" @click="editImages.push({ url: '' })">
                <fa icon="plus" class="margin-right-5" />Add Image
              </button>
            </div>

            <div class="column is-12 pad-top-0">

              <div v-for="(file, i) in editFiles" :key="i" class="pad margin-bottom bg-eee border-ccc" style="border-radius: 10px;">
                <div class="flex margin-bottom">
                  <div class="flex-1 bold">{{ `File ${i + 1}` }}</div>
                  <button class="button is-small" @click="editFiles.splice(i, 1)"><fa icon="times" /></button>
                </div>
                <b-field label="NAME" label-position="on-border">
                  <b-input v-model="file.name" expanded />
                </b-field>
                <b-field label="PATH" label-position="on-border">
                  <b-input v-model="file.path" expanded />
                </b-field>
                <b-field label="URL" label-position="on-border">
                  <b-input v-model="file.url" expanded />
                </b-field>
                <b-field label="BYTES" label-position="on-border">
                  <b-input type="number" v-model="file.bytes" expanded />
                </b-field>
                <b-field label="CHECKSUM" label-position="on-border">
                  <b-input v-model="file.checksum" expanded />
                </b-field>
              </div>

              <button class="button is-small" @click="editFiles.push({ name: '', path: '', bytes: null, url: '',  checksum: '' })">
                <fa icon="plus" class="margin-right-5" />Add File
              </button>
            </div>
          </div>

        </div>
        <div class="buttons margin-top-20 flex">
          <button class="button is-small is-danger" :disabled="submitting" :class="{ 'is-loading': deleting }" @click="deleteSelected()">Delete</button>
          <div class="flex-1"></div>
          <button class="button is-small is-success" :disabled="submitting || deleting" @click="submitForm()">Submit</button>
          <button class="button is-small" :disabled="deleting" :class="{ 'is-loading': submitting }" @click="showEditModal = false">Cancel</button>
        </div>
      </div>
    </b-modal>

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">
      <div class="toolbar flex">
        <div class="name">
          IAP Products >
          <span class="has-text-grey">{{ appId }}</span>
        </div>
        <div class="flex-1"></div>
        <div class="bold pulse font-size-170 text-ccc pad-left-20 pad-right-20" v-if="iapProducts === null">LOADING</div>
        <div class="buttons">
          <button class="button" @click="createCsv()">Get CSV String</button>
          <button class="button is-success" :disabled="submitting" @click="showAddProduct()">
            <fa icon="plus" class="margin-right" /> Add Product
          </button>
        </div>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-0 w-80px">THUMB</div>
        <div class="flex-table-cell">ID</div>
        <div class="flex-table-cell flex-2">Name</div>
        <div class="flex-table-cell flex-2">Files</div>
        <div class="flex-table-cell flex-1">Type</div>
        <div class="flex-table-cell flex-0 w-70px">$</div>
        <div class="flex-table-cell flex-0 w-70px">Active</div>
      </div>

      <div v-if="iapProducts && iapProducts.length > 0" class="flex-table-body">

        <div class="flex-table-row pointer" v-for="p in iapProducts" :key="p._id" @click="showEditProduct(p)">
          <div class="flex-table-cell flex-0 w-80px">
            <template v-if="p.thumbNailUrl">
              <div class="">
                <img v-if="p.thumbNailUrl" class="width-100 height-100" :src="p.thumbNailUrl" />
              </div>
            </template>
            <template v-else>
              <div class="height-100 center">
                <fa v-if="p.type=== 'raster map'" icon="map" size="3x" />
                <fa v-else-if="p.type=== 'poi'" icon="map-marker-alt" size="3x" />
              </div>
            </template>
          </div>
          <div class="flex-table-cell flex-1">{{ p.productId }}</div>
          <div class="flex-table-cell flex-2">
            <p class="ellipsis">{{ p.name }}</p>
            <p class="font-size-80 has-text-grey ellipsis">{{ $unescape(p.shortDescription) }}</p>
          </div>
          <div class="flex-table-cell flex-2">
            <div class="bold" v-if="p.type === 'raster map' && p.mapSetId"><router-link :to="`/raster-maps/sets/${p.mapSetId}`">{{ getMapSetName(p.mapSetId) }}</router-link></div>
            <div v-for="(file, i) in p.files" :key="i" class="flex tag margin-right margin-bottom-5">
              <div class="flex-1 b">{{ file.name }}</div>
              <div class="pad-left">{{ file.bytes | prettyFileSize }}</div>
            </div>
            <div v-for="(image, i) in p.images || []" :key="i">
              <img :src="image.url" class="width-100 margin-top-5" />
            </div>
          </div>
          <div class="flex-table-cell flex-1 uc">{{ p.type }}</div>
          <div class="flex-table-cell flex-0 w-70px">{{ p.free ? 'FREE' : 'PAID' }}</div>
          <div class="flex-table-cell flex-0 w-70px">
            <fa v-if="p.active" icon="check" class="has-text-success-dark font-size-120" />
            <fa v-else icon="times" class="has-text-grey-light font-size-120" />
          </div>
        </div>
      </div>

      <div v-else class="flex-table-body center pad-top-20 bold has-text-grey-light">
        <p>THERE ARE NO IAP PRODUCTS</p>
        <button class="button is-small margin-top" @click="showAddProduct()">
          <fa icon="plus" class="margin-right" /> Add Product
        </button>
      </div>

    </div>

  </div>
</template>

<script>
import SdfForm from '@/components/SdfForm'
import papa from 'papaparse'

export default {
  components: { SdfForm },
  data: () => ({
    iapProducts: null,
    mapSets: null,
    mapSetsMap: null,
    fields: [
      { key: 'productId', required: true },
      { key: 'name', required: true },
      { key: 'shortDescription', class: 'column is-12' },
      { key: 'description', input: 'textarea', class: 'column is-12' },
      { key: 'thumbNailUrl', title: 'Thumb Nail URL', class: 'column is-12' },
      { key: 'type', input: 'select', options: ['poi', 'raster map'] },
      { key: 'free', input: 'checkbox', class: 'column is-3' },
      { key: 'active', input: 'checkbox', class: 'column is-3' }
    ],
    selectedProduct: null,
    showEditModal: false,
    submitting: false,
    deleting: false,
    editImages: [],
    editFiles: [],
    editMapSetId: null,
    selectedIsRaster: false
  }),
  computed: {
    appId () { return this.$route.params.id }
  },
  watch: {
    showEditModal () {
      if (!this.showEditModal) this.selectedProduct = null
    }
  },
  created () {
    this.fetchProducts()
    this.subs.rasterMapSets = this.$fs.collection('rasterMapSets')
      .onSnapshot(ss => {
        this.mapSets = this.$ssToArr(ss)
        this.mapSetsMap = this.$ssToObjMap(ss)
      })
  },
  methods: {
    getMapSetName (mapSetId) {
      return (this.mapSetsMap && this.mapSetsMap[mapSetId] && this.mapSetsMap[mapSetId] && this.mapSetsMap[mapSetId].name) || '?'
    },
    async submitForm () {
      console.log('submitForm')
      const errs = []
      for (const image of this.editImages) {
        if (!`${image.url || ''}`.trim().length) {
          errs.push('Cannot have empty images.')
          break
        }
      }

      for (const file of this.editFiles) {
        for (const k in file) {
          if (!`${file[k] || ''}`.trim().length) {
            errs.push('Cannot have incomplete file info.')
            break
          }
        }
      }

      if (errs.length) {
        this.$showError(`<p>${errs.join('</p><p>')}</p>`)
        return
      }

      const data = this.$refs.form.submit()
      if (!data) return
      this.submitting = true
      try {
        data.appId = this.appId
        data.images = this.editImages
        data.files = this.editFiles
        data.mapSetId = this.editMapSetId
        data.shortDescription = this.$escape(data.shortDescription)
        data.description = this.$escape(data.description)
        console.log(data)
        await this.$fs.doc(`/iapProducts/${this.appId}_${data.productId}`).set(data)
        this.showEditModal = false
      } catch (error) {
        this.$showError(error)
      }
      this.submitting = false
    },
    async deleteSelected () {
      const confirmed = await this.$confirm({
        type: 'is-danger',
        title: 'Deleting Product',
        message: `Are you sure you want to delete <b>${this.selectedProduct.name}</b>`
      })
      if (!confirmed) return

      this.deleting = true
      try {
        await this.$fs.doc(`/iapProducts/${this.selectedProduct._id}`).delete()
        this.showEditModal = false
      } catch (error) {
        this.$showError(error)
      }
      this.deleting = false
    },
    fetchProducts () {
      this.unsub('iapProducst')
      this.iapProducts = null
      this.subs.iapProducts = this.$fs.collection('iapProducts')
        .where('appId', '==', this.appId)
        .onSnapshot(ss => {
          this.iapProducts = this.$ssToArr(ss).sort((a, b) => {
            const aStr = a.productId.trim().toLowerCase()
            const bStr = b.productId.trim().toLowerCase()
            return aStr.localeCompare(bStr)
          })
        })
    },
    showEditProduct (product) {
      this.selectedProduct = JSON.parse(JSON.stringify(product || {}))
      this.selectedProduct.shortDescription = this.$unescape(this.selectedProduct.shortDescription)
      this.selectedProduct.description = this.$unescape(this.selectedProduct.description)
      this.editImages = this.selectedProduct.images || []
      this.editFiles = this.selectedProduct.files || []
      this.editMapSetId = this.selectedProduct.mapSetId || null
      this.selectedIsRaster = this.selectedProduct.type === 'raster map'
      this.showEditModal = true
    },
    showAddProduct () {
      this.editImages = []
      this.editFiles = []
      this.editMapSetId = null
      this.selectedIsRaster = false
      this.showEditProduct(null)
    },
    createCsv () {
      const headers = ['Product ID', 'Published State', 'Purchase Type', 'Auto Translate', 'Locale; Title; Description', 'Auto Fill Prices', 'Price', 'Pricing Template ID']
      const rows = []

      const price99Id = '4635021129579314485'

      for (const p of this.iapProducts) {
        if (p.free) continue
        if (!p.active) continue
        const row = {}
        for (const k of headers) {
          let v = ''
          if (k === 'Product ID') v = p.productId
          else if (k === 'Published State') v = 'published'
          else if (k === 'Purchase Type') v = 'managed_by_android'
          else if (k === 'Auto Translate') v = 'false'
          else if (k === 'Locale; Title; Description') v = `en-AU; ${p.name}; ${this.$unescape(p.shortDescription) || p.name}`
          else if (k === 'Auto Fill Prices') v = 'false'
          else if (k === 'Price') v = ''
          else if (k === 'Pricing Template ID') v = price99Id
          row[k] = v
        }
        rows.push(row)
      }
      console.table(rows)
      const csvStr = papa.unparse(rows)
      console.log(csvStr)
    }
  }
}
</script>

<style lang="scss">
.iap-products {
  img {
    object-fit: contain;
  }

  .modal-content {
    height: 100vh;
  }
  .modal-content .box {
    max-height: calc(100vh - 50px);
  }
}
</style>
