<template>
  <div class="raster-map-sets height-100">

    <div v-if="items === null" class="height-100 flex-middle font-size-200 has-text-grey-light">
      <div>
        <fa icon="spinner" size="2x" pulse />
      </div>
    </div>

    <div v-else-if="!items">
      <b-message type="is-danger">Could no load raster map sets?</b-message>
    </div>

    <div v-else class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name">
          Raster Map Sets
        </div>
        <div class="flex-1"></div>
        <div class="buttons">
          <router-link class="button is-info" to="/raster-maps/set-code/none"><fa icon="search" class="margin-right" />Code Lookup</router-link>
        </div>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-1">Created</div>
        <div class="flex-table-cell flex-4"><b-input v-model="nameStr" size="is-small" placeholder="Name" /></div>
        <div class="flex-table-cell flex-2">Map Count</div>
      </div>

      <div v-if="items" class="flex-table-body">
        <router-link v-for="item in filteredItems" :key="item._id" :to="`/raster-maps/sets/${item._id}`" class="flex-table-row">
          <div class="flex-table-cell flex-1 pointer">{{ item.timestampCreated | formatDate }}</div>
          <div class="flex-table-cell flex-4 pointer">{{ item.name }}</div>
          <div class="flex-table-cell flex-2 pointer">{{ item.mapIds.length }}</div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      items: null,
      nameStr: '',
      codeData: false,
      codeMapSetData: null,
      codeBatchData: null
    }
  },
  computed: {
    filteredItems () {
      const str = this.nameStr.trim().toLocaleLowerCase()
      return (this.items || []).filter(item => item.name.trim().toLocaleLowerCase().indexOf(str) >= 0)
    }
  },
  created () {
    this.subs.mapSets = this.$fs.collection('rasterMapSets')
      .orderBy('name')
      .onSnapshot(ss => {
        this.items = this.$ssToArr(ss)
      })
  }
}
</script>

<style lang="scss">
.raster-map-sets {
  .no-border-top {
    border-top-color: red !important;
  }
}
</style>
