<template>
  <div class="device-info">

    <b-modal :active.sync="showModal">
      <div class="box flex-col">
        <div class="bold font-size-150">Device Info</div>
        <div class="flex-1 overflow-y-auto pad border-eee margin-top">
          <div v-for="item in sortedInfo" :key="item.key" class="flex">
            <div class="flex-1 bold">{{ item.key | unCamel }}</div>
            <div class="flex-2">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </b-modal>

    <div @click="showModal = true" class="pointer" :class="{ oneLine }">
      <div>{{ deviceInfo.deviceName }}</div>
      <div class="has-text-grey font-size-90"><b>{{ deviceInfo.deviceType }}</b> {{ deviceInfo.graphicsDeviceName }}</div>
      <div class="has-text-grey-light font-size-80">more...</div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['deviceInfo', 'oneLine'],
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    sortedInfo () {
      const arr = []
      for (const key in this.deviceInfo) {
        arr.push({ key, value: this.deviceInfo[key] })
      }
      return arr.sort((a, b) => a.key.localeCompare(b.key))
    }
  }
}
</script>

<style lang="scss">
.device-info {
  .oneLine div {
    display: inline;
  }
}
</style>
