<template>
  <div class="icons height-100">

    <b-modal :active.sync="showJsonModal">
      <div class="box flex-col" style="width: 100%; max-width: 90vw;">
        <div class="flex">
          <div class="flex-1 bold font-size-200">Icons JSON</div>
          <div><b-switch v-model="escapeJsonStr">Escape String</b-switch></div>
        </div>
        <div class="flex-1 flex-middle">
          <fa v-if="jsonStrValue === null" icon="spinner" size="5x" style="opacity: 0.2" pulse />
          <textarea ref="jsonStrEl" v-if="jsonStrValue" class="height-100 width-100" v-model="jsonStrValue"></textarea>
        </div>
        <div class="buttons margin-top">
          <button class="button" :disabled="!jsonStrValue" @click="CopyJsonStr()">Copy</button>
        </div>
      </div>
    </b-modal>

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">
      <div class="toolbar flex">
        <div class="flex-1 name">Icons</div>
        <div class="name pad-right-20 has-text-grey"> {{ poiTypesCount }} / {{ poiTypes.length }} Icons Set</div>
        <div class="buttons">
          <button class="button" @click="$back()">Back</button>
          <b-dropdown :triggers="['hover']" aria-role="list" position="is-bottom-left">
            <template #trigger>
              <button class="button"><fa icon="caret-down" /></button>
            </template>
            <b-dropdown-item aria-role="listitem" @click="showJsonModal = true">Get Json String</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="flex-table-header">
        <!-- <div class="flex-table-cell flex-1">type</div> -->
        <div class="flex-table-cell flex-1 flex">
          <div>KEY</div>
          <div class="flex-1 pad-left-20">
            <b-input size="is-small" v-model="searchStr" placeholder="SEARCH" />
          </div>
        </div>
        <div class="flex-table-cell flex-1">ICON</div>
        <div class="flex-table-cell flex-1"></div>
      </div>

      <div class="flex-table-body">
        <template v-for="key in filteredPoiTypes">
          <div class="flex-table-row pointer" :key="key" :class="{'is-selected': submitting === key}" @click="updateIcon(key)">
            <div class="flex-table-cell flex-1">{{ key }}</div>
            <div class="flex-table-cell flex-1">
              <div class="fa center font-size-120" v-html="getIcon(key)"></div>
            </div>
            <div class="flex-table-cell flex-1">
              <div class="fa has-text-grey-light">&#xf044;</div>
            </div>
          </div>

        </template>
      </div>

    </div>

  </div>
</template>

<script>
import poiTypes from '@/assets/poi-types'
export default {
  data () {
    return {
      searchStr: '',
      poiTypesCount: 0,
      poiTypes,
      poiIcons: null,
      submitting: null,
      showJsonModal: false,
      escapeJsonStr: true,
      jsonStrValue: ''
    }
  },
  computed: {
    filteredPoiTypes () {
      const str = this.searchStr.trim().toLocaleLowerCase()
      return str.length ? this.poiTypes.filter(t => t.indexOf(str) >= 0) : this.poiTypes
    }
  },
  watch: {
    escapeJsonStr () { this.updateJsonStrValue() }
  },
  created () {
    this.subs.poiIcons = this.$fs.collection('poiIcons')
      .onSnapshot(ss => {
        this.poiTypesCount = ss.docs.length
        this.poiIcons = this.$ssToObjMap(ss)
        this.updateJsonStrValue()
      })
  },
  methods: {
    CopyJsonStr () {
      const copyText = this.$refs.jsonStrEl
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open('copied to clipboard')
    },
    updateJsonStrValue () {
      let poiIcons = null
      if (this.poiIcons) {
        poiIcons = {}
        for (const key in this.poiIcons) {
          poiIcons[key] = this.poiIcons[key].iconCode
        }
      }
      this.jsonStrValue = poiIcons ? JSON.stringify(poiIcons) : null
      if (this.jsonStrValue && this.escapeJsonStr) {
        let str = JSON.stringify(String(this.jsonStrValue))
        str = str.substring(1, str.length - 1)
        this.jsonStrValue = str
      }
    },
    async updateIcon (key) {
      const current = this.poiIcons[key]
      this.submitting = key
      let iconCode = await this.$prompt({
        type: 'is-info',
        title: 'Set Icon',
        message: `${key} <span class="fa">${this.getIcon(key)}</span>`,
        inputAttrs: {
          type: 'text',
          placeholder: 'icon code',
          value: current ? current.iconCode : '',
          required: false
        }
      })
      if (iconCode !== null) {
        iconCode = iconCode.trim()
        try {
          await this.$fs.doc(`/poiIcons/${key}`).set({ iconCode })
        } catch (error) {
          this.$showErrr(error)
        }
      }
      this.submitting = 0
    },
    getIcon (key) {
      if (this.poiIcons === null) return ''
      const v = this.poiIcons[key]
      if (v) {
        const code = v.iconCode
        if (!code.length) return ''
        if (code.indexOf('text ') === 0) return code
        return this.getIconStr(code)
      }
      return ''
    },
    getIconStr (code) {
      return `&#x${code};`
    }
  }
}
</script>
