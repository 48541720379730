<template>
  <div class="engine-settings height-100">

    <b-modal :active.sync="showJsonModal">
      <div class="box flex-col" style="width: 100%; max-width: 90vw;">
        <div class="flex">
          <div class="flex-1 bold font-size-200">Settings JSON</div>
          <div><b-switch v-model="escapeJsonStr">Escape String</b-switch></div>
        </div>
        <div class="flex-1 flex-middle">
          <fa v-if="jsonStr === null" icon="spinner" size="5x" style="opacity: 0.2" pulse />
          <textarea ref="jsonStrEl" v-if="jsonStr" class="height-100 width-100" v-model="jsonStrValue"></textarea>
        </div>
        <div class="buttons margin-top">
          <button class="button" :disabled="!jsonStr" @click="CopyJsonStr()">Copy</button>
        </div>
      </div>
    </b-modal>

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="flex-1 name">
          Engine Setttings >
          <span class="has-text-grey">{{ docId }}</span>
          <span v-if="doc && doc.timestamp" class="font-size-60 pad-left-20">{{ $moment.unix(doc.timestamp).format('DD/MM/YYYY h:mm:ssA') }}</span>
        </div>
        <div class="bold pulse font-size-170 text-ccc pad-leff-20" v-if="doc === null">LOADING</div>
        <div class="bold text-red font-size-170" v-else-if="doc === false">ERROR</div>
        <div v-else class="buttons">
          <button class="button is-success" :class="{'is-loading': submitting}" :disabled="!dirty" @click="submit()">Update</button>
          <!-- <button class="button is-danger" @click="onDelete()">delete</button>
          <button class="button" @click="reset()" :disabled="!dirty">Reset</button>
          <button class="button" @click="GetJsonString()">Get Json String</button>
          <router-link :to="`/engine-settings/banners/${docId}`" class="button">Banners</router-link> -->
          <button @click="$back()" class="button">Back</button>

          <b-dropdown :triggers="['hover']" aria-role="list" position="is-bottom-left">
            <template #trigger>
              <button class="button"><fa icon="caret-down" /></button>
            </template>
            <b-dropdown-item aria-role="listitem" @click="reset()" :disabled="!dirty">Reset</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="submit()">Force Update</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="GetJsonString()">Get Json String</b-dropdown-item>
            <b-dropdown-item has-link aria-role="listitem">
              <router-link :to="`/engine-settings/banners/${docId}`">Banners</router-link>
            </b-dropdown-item>
            <b-dropdown-item has-link aria-role="listitem">
              <router-link :to="`/engine-settings/data-files/${docId}`">Data Files</router-link>
            </b-dropdown-item>
            <b-dropdown-item has-link aria-role="listitem">
              <router-link :to="`/engine-settings/iap-products/${docId}`">IAP Products</router-link>
            </b-dropdown-item>
            <b-dropdown-item has-link aria-role="listitem">
              <router-link :to="`/engine-settings/apk-updates/${docId}`">APK Updates</router-link>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" class="has-text-danger" @click="onDelete()">Delete</b-dropdown-item>
          </b-dropdown>

        </div>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-2 flex">
          <div>Name</div>
          <div class="flex-1 pad-left-20">
            <b-input size="is-small" v-model="searchStr" placeholder="SEARCH" />
          </div>
        </div>
        <div class="flex-table-cell flex-1">Value</div>
        <div class="flex-table-cell flex-1">Type</div>
        <div class="flex-table-cell flex-2">Edit</div>
      </div>

      <div class="flex-table-body" v-if="doc">
        <!-- <template v-for="(_, key) in defaultEngineSettings"> -->
        <template v-for="key in filteredKeys">
          <div class="flex-table-row" v-if="key !== '_id' && key !== 'timestamp'" :key="key" :class="{ changed: changes[key] !== undefined }">
            <div class="flex-table-cell flex-2">
              <div class="bold">{{ key | unCamel }}</div>
              <div class="font-size-80">{{ key }}</div>
            </div>
            <div class="flex-table-cell flex-1">{{ doc[key] }}</div>
            <div class="flex-table-cell flex-1">{{ type(key) }}</div>
            <div class="flex-table-cell flex-2 flex">
              <div class="flex-1">
                <template v-if="isBool(key)">
                  <b-checkbox v-model="editDoc[key]" @input="onInput(key)" expanded>{{ editDoc[key] }}</b-checkbox>
                </template>
                <template v-else>
                  <b-input v-model="editDoc[key]" size="is-small" :type="getInputType(key)" @input="onInput(key)" />
                </template>
              </div>
              <div :style="{ visibility: doc[key] !== editDoc[key] ? 'visible' : 'hidden'}">
                <button class="button margin-left is-small" @click="reset(key)">reset</button>
              </div>
            </div>
          </div>
        </template>
      </div>

    </div>

  </div>
</template>

<script>
import defaultEngineSettings from '@/assets/defaultEngineSettings.json'
export default {
  data () {
    return {
      searchStr: '',
      keys: [],
      defaultEngineSettings,
      doc: null,
      editDoc: null,
      dirty: false,
      submitting: false,
      showJsonModal: false,
      jsonStr: null,
      jsonStrValue: null,
      escapeJsonStr: true,
      changes: {}
    }
  },
  computed: {
    docId () { return this.$route.params.id },
    filteredKeys () {
      const str = this.searchStr.trim().toLocaleLowerCase()
      return !str.length ? this.keys : this.keys.filter(k => k.toLocaleLowerCase().indexOf(str) >= 0)
    }
  },
  watch: {
    docId () { this.fetchDoc() },
    jsonStr () { this.updateJsonStrValue() },
    escapeJsonStr () { this.updateJsonStrValue() }
  },
  async created () {
    // await this.$wait(1000)
    const keys = []
    for (const k in defaultEngineSettings) keys.push(k)
    this.keys = keys
    this.fetchDoc()
  },
  methods: {
    updateJsonStrValue () {
      this.jsonStrValue = this.jsonStr
      if (this.jsonStrValue && this.escapeJsonStr) {
        let str = JSON.stringify(String(this.jsonStrValue))
        str = str.substring(1, str.length - 1)
        this.jsonStrValue = str
      }
    },
    CopyJsonStr () {
      const copyText = this.$refs.jsonStrEl
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open('copied to clipboard')
    },
    async submit () {
      this.submitting = true
      this.editDoc.timestamp = this.$moment().unix()
      try {
        await this.$fs.doc(`/engineSettings/${this.docId}`).update(this.editDoc)
      } catch (error) {
        this.$showError(error)
      }
      this.submitting = false
    },
    fetchDoc () {
      this.unsub('engineSettings')
      console.log(this.docId)
      this.subs.engineSettings = this.$fs.doc(`/engineSettings/${this.docId}`)
        .onSnapshot(ss => {
          const doc = this.$ssToObj(ss)
          this.doc = JSON.parse(JSON.stringify(this.defaultEngineSettings))
          for (const k in doc) {
            this.doc[k] = doc[k]
          }
          this.reset()
        })
    },
    reset (k) {
      if (k) {
        this.editDoc[k] = this.doc[k]
        this.checkChange(k)
      } else {
        this.editDoc = JSON.parse(JSON.stringify(this.doc))
        this.changes = {}
        this.dirty = false
      }
    },
    async GetJsonString () {
      this.showJsonModal = true
      try {
        const ss = await this.$fs.doc(`/engineSettings/${this.docId}`).get()
        const data = this.$ssToObj(ss)
        this.jsonStr = JSON.stringify(data)
      } catch (error) {
        this.$showError(error)
      }
    },
    onInput (k) {
      if (typeof this.doc[k] === 'number') {
        this.editDoc[k] = Number(this.editDoc[k])
      }
      this.checkChange(k)
    },
    checkChange (k) {
      if (this.doc[k] !== this.editDoc[k]) {
        this.changes[k] = this.editDoc[k]
      } else {
        delete this.changes[k]
      }
      this.dirty = Object.keys(this.changes).length > 0
    },
    isBool (k) {
      return typeof this.doc[k] === 'boolean'
    },
    getInputType (k) {
      return typeof this.doc[k] === 'string' ? 'text' : 'number'
    },
    type (k) {
      return typeof this.doc[k]
    },
    async onDelete () {
      console.log('onDelete')
      const confirmed = await this.$confirm({
        type: 'is-danger',
        title: 'Deleting Settings',
        message: `Are you sure you want to delete settings "${this.docId}"`
      })
      if (!confirmed) return
      console.log('confirmed')
      try {
        await this.$fs.doc(`/engineSettings/${this.docId}`).delete()
        this.$router.go(-1)
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>

<style lang="scss">
  .engine-settings {
    tr.changed th,
    tr.changed td {
      background-color:rgba($color: green, $alpha: 0.1);
    }

    .delete-container {
      position: fixed;
      bottom: 0px;
      right: 0px;
      padding: 0 10px 10px 0;
    }
  }
</style>
