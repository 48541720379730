<template>
  <div id="app">
    <!-- SIGNED IN -->
    <template v-if="user">
      <nav class="navbar is-dark is-fixed-top" role="navigation" aria-label="main navigation" style="z-index: 10;">
        <div class="navbar-brand">
          <router-link to="/" class="navbar-item">
            <img src="./assets/logo.png">
          </router-link>

          <div class="navbar-item bold has-text-grey-light">
            AM Admin
          </div>

          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" :class="{ 'is-active': navIsActive }" @click="navIsActive = !navIsActive">
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <div class="navbar-menu" :class="{ 'is-active': navIsActive }">
          <div class="navbar-start">

            <div class="navbar-item has-dropdown is-hoverable">
              <router-link class="navbar-link" to="/engine-settings">Engine Settings</router-link>
              <div class="navbar-dropdown is-boxed">
                <router-link class="navbar-item" to="/engine-settings">Engine Settings</router-link>
                <router-link class="navbar-item" to="/icons">Icons</router-link>
              </div>
            </div>

            <div class="navbar-item has-dropdown is-hoverable">
              <router-link class="navbar-link" to="/auth">Authentication</router-link>
              <div class="navbar-dropdown is-boxed">
                <router-link class="navbar-item" to="/auth">Authentication</router-link>
                <router-link class="navbar-item" to="/auth/devices">Devices</router-link>
                <router-link class="navbar-item" to="/auth/activations">Activations</router-link>
                <router-link class="navbar-item" to="/auth/log">Log</router-link>
              </div>
            </div>

            <router-link class="navbar-item" to="/map-styles">Map Styles</router-link>

            <div class="navbar-item has-dropdown is-hoverable">
              <router-link class="navbar-link" to="/raster-maps">Raster Maps</router-link>
              <div class="navbar-dropdown is-boxed">
                <router-link class="navbar-item" to="/raster-maps">Raster Maps</router-link>
                <router-link class="navbar-item" to="/raster-maps/sets">Map Sets</router-link>
                <router-link class="navbar-item" to="/raster-maps/add">Add Maps</router-link>
                <router-link class="navbar-item" to="/raster-maps/set-code/none">Code Lookup</router-link>
                <router-link class="navbar-item" to="/raster-maps/code-log">Code Log</router-link>
              </div>
            </div>

          </div>

          <div class="navbar-end">
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                <fa icon="user" class="margin-right" />{{ user.email }}
              </a>

              <div class="navbar-dropdown is-right">
                <a class="navbar-item right" @click="onSignOut()">
                  <fa icon="sign-out-alt" class="margin-right" /> Sign Out
                </a>
              </div>
            </div>
          </div>

        </div>
      </nav>
      <div class="page-content">
        <router-view />
      </div>
    </template>

    <template v-else-if="user === false">
      <sign-in v-if="user === false" />
    </template>

    <template v-else-if="user === null">
      <div class="height-100 flex-middle font-size-200 has-text-grey-light">
        <fa icon="spinner" size="2x" pulse />
      </div>
    </template>

  </div>
</template>

<script>
import SignIn from '@/components/SignIn'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  components: { SignIn },
  data () {
    return {
      navIsActive: false
    }
  },
  computed: {
    user () { return this.$store.getters.user }
  },
  methods: {
    onSignOut () {
      this.navRightIsActive = false
      firebase.auth().signOut()
    }
  }
}
</script>

<style lang="scss">
  #app {
    padding-top: var(--nav-height);
    .page-content {
      height: calc(100vh - var(--nav-height));
    }
  }
</style>
