<template>
  <div class="banners">

    <div class="toolbar flex">
      <div class="flex-1 name">Banners {{ appId }}</div>
      <div class="buttons">
        <router-link :to="`/engine-settings/${appId}`" class="button">Settings</router-link>
        <button class="button" @click="$back()">Back</button>
      </div>
    </div>
    <div class="toolbar-spacer"></div>

    <div class="type box margin" v-for="(arr, type) in images" :key="type">
      <div class="bold font-size-200 uc">{{ type }}</div>

      <div class="flex grid" style="flex-wrap: wrap">
        <div v-if="items === null" class="banner flex-middle has-text-grey-light w-200px">
          <fa icon="spinner" size="5x" pulse />
        </div>
        <div v-else class="banner">
          <file-dropper accept="image/png,image/x-png" @files="upload($event, type)" :loading="loading === type" />
        </div>

        <div class="banner" v-for="img in arr" :key="img._id" :style="'opacity:' + (deleting === img._id ? 0.2 : 1)">
          <div class="right" style="height: 40px; margin-bottom: -40px">
            <div class="pad-5">
              <button class="button is-small is-danger" @click="onDelete(img)"><fa icon="times" /></button>
            </div>
          </div>
          <img :src="img.url" />
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import FileDropper from '@/components/FileDropper'
import firebase from 'firebase/app'
import 'firebase/storage'
export default {
  components: {
    FileDropper
  },
  data () {
    return {
      loading: false,
      deleting: null,
      items: null,
      images: {
        promo: [],
        banner: []
      }
    }
  },
  computed: {
    user () { return this.$store.getters.user },
    appId () { return this.$route.params.id }
  },
  created () {
    this.subs.banners = this.$fs.collection('banners')
      .where('appId', '==', this.appId)
      .orderBy('timestamp', 'asc')
      .onSnapshot(ss => {
        this.items = this.$ssToArr(ss) || []
        this.images.promo = this.items.filter(item => item.type === 'promo')
        this.images.banner = this.items.filter(item => item.type === 'banner')
      })
  },
  methods: {
    async onDelete (img) {
      const confirm = await this.$confirm({
        type: 'is-danger',
        title: 'Delete Image',
        message: 'Are you sure you want to delete this image'
      })
      if (!confirm) return
      // console.log('onDelete', img)

      this.deleting = img._id
      try {
        await firebase.storage().ref(img.path).delete()
        await this.$fs.doc(`/banners/${img._id}`).delete()
      } catch (error) {
        this.$showError(error)
      }
      this.deleting = null
    },
    uploadBanner (files) {
      this.upload(files, 'banner')
    },
    uploadPromo (files) {
      this.upload(files, 'promo')
    },
    async upload (files, type) {
      if (!files.length) return
      const file = files[0]
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      // console.log(file.name, fileType)
      if (fileType !== '.png') {
        this.$showError('banner must be a png image')
        return
      }

      this.loading = type
      try {
        const timestamp = this.$moment().unix()
        const storageRef = firebase.storage().ref()
        const imageRef = storageRef.child(`${this.appId}/promos/${timestamp}-${file.name}`)
        const snapshot = await imageRef.put(file)
        if (snapshot.state !== 'success') {
          throw new Error(snapshot.state)
        }
        const data = {
          type,
          appId: this.appId,
          path: snapshot.ref.fullPath,
          url: await snapshot.ref.getDownloadURL(),
          timestamp,
          date: new Date()
        }
        await this.$fs.collection('banners').add(data)
      } catch (error) {
        this.$showError(error)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.banners {
  .banner {
    button { display: none; }
  }
  .banner:hover button {
    display: inline-block;
  }

  .banner,
  .upload-container {
    height: 200px;
    margin: 10px;
  }

  .banner img {
    height: 200px;
  }

}
</style>
