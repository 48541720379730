<template>
  <div class="data-files height-100">

    <b-modal :active.sync="showEditModal">
      <div class="box flex-col">
        <div class="bold font-size-120">Upload Data File JSON</div>
        <div class="flex-1 margin-top-20">
          <textarea v-model="jsonStr" class="input height-100"></textarea>
        </div>
        <div class="buttons margin-top-20 flex">
          <div class="flex-1"></div>
          <button class="button is-small is-success" :disabled="!jsonStr.length" @click="submitJson()">Submit JSON</button>
          <button class="button is-small" @click="showEditModal = false">Cancel</button>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="showCopyModal">
      <div class="box flex-col">
        <div class="bold font-size-120">Copy Data Files</div>
        <div class="flex-1 margin-top-20">

          <div v-if="!selectedCopyId">
            <div class="bold uc pad-bottom">Select an app ID to copy from</div>
            <div class="margin-bottom-5" v-for="(item, k) in allDataFiles" :key="k">
              <button class="button is-small width-100" @click="selectedCopyId = item">{{ k }}</button>
            </div>
          </div>

          <div v-else>
            <template v-for="(item, k) in selectedCopyId">
              <div class="margin-bottom" v-if="k !== '_id'" :key="k">
                <b-checkbox type="is-dark" size="is-medium" v-model="selectedCopyFiles" :native-value="k">
                    <span class="bold">{{ item.label }}</span>
                    <span class="pad-left">v{{ item.version }}</span>
                    <span class="pad-left">{{ getDate(item.version) }}</span>
                </b-checkbox>
              </div>
            </template>
          </div>

        </div>
        <div class="buttons margin-top-20 flex">
          <div class="flex-1"></div>
          <button class="button is-small is-success" :disabled="!selectedCopyFiles.length" @click="onCopy()">Copy</button>
          <button class="button is-small" @click="showCopyModal = false">Cancel</button>
        </div>
      </div>
    </b-modal>

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name">
          Data Files >
          <span class="has-text-grey">{{ appId }}</span>
        </div>
        <div class="flex-1"></div>
        <div class="bold pulse font-size-170 text-ccc pad-leff-20" v-if="dataFiles === null">LOADING</div>
        <div class="buttons">
          <button class="button" @click="showCopyModal = true">Copy</button>
          <button class="button is-success" @click="showEditModal = true">Submit Data File JSON</button>
        </div>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-2">Label</div>
        <div class="flex-table-cell flex-1">Name</div>
        <div class="flex-table-cell flex-2">File Name</div>
        <div class="flex-table-cell flex-2">Url</div>
        <div class="flex-table-cell flex-1">Version</div>
        <div class="flex-table-cell flex-1">Date</div>
        <div class="flex-table-cell flex-1">Bytes</div>
        <div class="flex-table-cell flex-1">Size</div>
        <div class="flex-table-cell flex-0 w-60px"></div>
      </div>

      <div class="flex-table-body" v-if="dataFileCount">
        <div class="flex-table-row" v-for="dataFile in dataFilesArr" :key="dataFile.name">
          <div class="flex-table-cell flex-2 pointer" @click="editLabel(dataFile)">
            <span class="pad-right-5">
              <fa icon="edit" />
            </span>
            {{ dataFile.label }}
          </div>
          <div class="flex-table-cell flex-1">{{ dataFile.name }}</div>
          <div class="flex-table-cell flex-2">{{ dataFile.file_name }}</div>
          <div class="flex-table-cell flex-2"><a :href="dataFile.url" target="_blank">{{ dataFile.url }}</a></div>
          <div class="flex-table-cell flex-1">{{ dataFile.version }}</div>
          <div class="flex-table-cell flex-1">{{ getDate(dataFile.version) }}</div>
          <div class="flex-table-cell flex-1">{{ dataFile.bytes_total }}</div>
          <div class="flex-table-cell flex-1">{{ dataFile.bytes_total | prettyFileSize }}</div>
          <div class="flex-table-cell flex-0 w-60px">
            <button class="button is-small" @click="remove(dataFile)"><fa icon="times" /></button>
          </div>
        </div>
      </div>
      <div class="flex-table-body center pad-top-20 bold has-text-grey-light" v-else>
        <p>THERE ARE NO DATA FILES</p>
        <button class="button is-small margin-top" @click="showEditModal = true">Submit Data File JSON</button>
      </div>

    </div>

  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  data () {
    return {
      dataFiles: null,
      allDataFiles: null,
      showEditModal: false,
      showCopyModal: false,
      selectedCopyId: null,
      selectedCopyFiles: [],
      jsonStr: '',
      editFields: [
        { key: 'fileName', require: true },
        { key: 'bytes', input: 'number', require: true },
        { key: 'url', require: true, class: 'column is-12' }
      ]
    }
  },
  computed: {
    appId () { return this.$route.params.id },
    dataFileCount () {
      if (!this.dataFiles) return 0
      return Object.keys(this.dataFiles).length
    },
    dataFilesArr () {
      const arr = []
      for (const key in this.dataFiles || {}) {
        if (key === '_id') continue
        arr.push(this.dataFiles[key])
      }
      return arr.sort((a, b) => b.version - a.version)
    }
  },
  watch: {
    showCopyModal () {
      this.selectedCopyId = null
      this.selectedCopyFiles = []
    }
  },
  created () {
    this.fetchDataFiles()
  },
  methods: {
    getDate (version) {
      const str = `${version}`.split('.')[0]
      if (str.length !== 6) return '-'
      return `${str.substr(4, 2)}/${str.substr(2, 2)}/20${str.substr(0, 2)}`
    },
    fetchDataFiles () {
      this.unsub('dataFiles')
      this.subs.dataFiles = this.$fs.collection('dataFiles')
        .onSnapshot(ss => {
          this.allDataFiles = this.$ssToObjMap(ss)
          // for (const key in this.allDataFiles) {
          //   delete this.allDataFiles[key]._id
          // }
          this.dataFiles = this.allDataFiles[this.appId] || false
        })
    },
    async submitJson () {
      console.log('submitJson')

      try {
        const obj = JSON.parse(this.jsonStr)

        const errs = []
        for (const key of ['label', 'name', 'file_name', 'version', 'url', 'bytes_total', 'checksum']) {
          if (obj[key] === undefined) {
            errs.push(key)
          }
        }
        if (errs.length) throw new Error('No ' + errs.join(', '))

        const confirmed = await this.$confirm({
          type: 'is-info',
          title: `${this.dataFiles[obj.name] ? 'Updating' : 'Adding'} Data File`,
          message: `
            <p>Are you sure you want to ${this.dataFiles[obj.name] ? 'update' : 'add'} data file</p>
            <p class="bold">${obj.name}</p>`
        })
        if (!confirmed) return

        const updateData = {}
        updateData[obj.name] = obj
        await this.$fs.doc(`/dataFiles/${this.appId}`).update(updateData)

        this.jsonStr = ''
        this.showEditModal = false
      } catch (error) {
        this.$showError(error)
      }
    },
    async remove (dataFile) {
      const confirmed = await this.$confirm({
        type: 'is-danger',
        tile: 'Removind Data File',
        message: `
          <p>Are you sure you want to remoce this data file?</p>
          <p class="bold">${dataFile.name}</p>
        `
      })
      if (!confirmed) return

      try {
        const updateData = {}
        updateData[dataFile.name] = firebase.firestore.FieldValue.delete()
        await this.$fs.doc(`/dataFiles/${this.appId}`).update(updateData)
      } catch (error) {
        this.$showError(error)
      }
    },
    async editLabel (dataFile) {
      const value = await this.$prompt({
        type: 'is-info',
        title: 'Edit Label',
        message: `Update the label for ${dataFile.name}`,
        inputAttrs: {
          placeholder: 'Label',
          value: dataFile.label
        },
        confirmText: 'Update',
        trapFocus: true
      })
      console.log(value)
      if (value === null) return

      try {
        const updateData = {}
        updateData[`${dataFile.name}.label`] = value
        await this.$fs.doc(`/dataFiles/${this.appId}`).update(updateData)
      } catch (error) {
        this.$showError(error)
      }
    },
    async onCopy () {
      const confirmed = await this.$confirm({
        type: 'is-info',
        tile: 'Copy Data File',
        message: `
          <p>Are you sure you want to copy these data file fomr ${this.selectedCopyId}?</p>
          <p class="bold">${this.selectedCopyFiles.join(', ')}</p>
        `
      })
      if (!confirmed) return

      try {
        const updateData = {}
        for (const key of this.selectedCopyFiles) {
          updateData[key] = this.selectedCopyId[key]
        }
        console.log(updateData)
        await this.$fs.doc(`/dataFiles/${this.appId}`).update(updateData)

        this.showCopyModal = false
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
