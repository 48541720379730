import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: () => import('@/views/Home.vue') },

  { path: '/engine-settings', component: () => import('@/views/EngineSettings/Settings.vue') },
  { path: '/engine-settings/:id', component: () => import('@/views/EngineSettings/EngineSettings.vue') },
  { path: '/engine-settings/banners/:id', component: () => import('@/views/EngineSettings/Banners.vue') },
  { path: '/engine-settings/data-files/:id', component: () => import('@/views/EngineSettings/DataFiles.vue') },
  { path: '/engine-settings/iap-products/:id', component: () => import('@/views/EngineSettings/IapProducts.vue') },
  { path: '/engine-settings/apk-updates/:id', component: () => import('@/views/EngineSettings/ApkUpdates.vue') },
  { path: '/icons', component: () => import('@/views/EngineSettings/Icons.vue') },

  { path: '/auth', component: () => import('@/views/Auth/Auth.vue') },
  { path: '/auth/devices', component: () => import('@/views/Auth/Devices.vue') },
  { path: '/auth/activations', component: () => import('@/views/Auth/Activations.vue') },
  { path: '/auth/log', component: () => import('@/views/Auth/Log.vue') },

  { path: '/map-styles', component: () => import('@/views/MapStyles/MapStyles.vue') },
  { path: '/map-styles/:id', component: () => import('@/views/MapStyles/MapStyleEditor.vue') },

  { path: '/raster-maps', component: () => import('@/views/RasterMaps/RasterMaps.vue') },
  { path: '/raster-maps/add', component: () => import('@/views/RasterMaps/AddRasterMaps.vue') },
  { path: '/raster-maps/sets', component: () => import('@/views/RasterMaps/RasterMapSets.vue') },
  { path: '/raster-maps/sets/:id', component: () => import('@/views/RasterMaps/RasterMapSet.vue') },
  { path: '/raster-maps/code-log', component: () => import('@/views/RasterMaps/CodeLog.vue') },
  { path: '/raster-maps/set-code/:code', component: () => import('@/views/RasterMaps/RasterMapSetCode.vue') },

  { path: '/test', component: () => import('@/views/Test.vue') }
]

const router = new VueRouter({ mode: 'history', routes })

export default router
