<template>
  <div class="log height-100">

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name flex-1">Activation Logs</div>
        <div class="buttons">
          <button class="button" @click="$back()">Back</button>
        </div>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-1">Timestamp</div>
        <div class="flex-table-cell flex-1">IP</div>
        <div class="flex-table-cell flex-1">App</div>
        <div class="flex-table-cell flex-1">Device Info</div>
        <div class="flex-table-cell flex-3">Response</div>
      </div>
      <div v-if="items" class="flex-table-body">
        <div v-for="item in items" :key="item._id" class="flex-table-row">
          <div class="flex-table-cell flex-1">{{ item.timestamp | formatDateTime }}</div>
          <div class="flex-table-cell flex-1">
            <span class="ip">
              <ip-address :ip="item.ip">
                <div class="flex">
                  <div class="flex-1">{{ item.ip }}</div>
                  <div>{{ item.timestamp | formatDateTime }}</div>
                </div>
              </ip-address>
            </span>
            <div v-if="item.deviceInfo.code" class="has-text-grey font-size-90">
              <b>CODE:</b> {{ item.deviceInfo.code }}
            </div>
          </div>
          <div class="flex-table-cell flex-1">{{ item.deviceInfo.appName }}</div>
          <div class="flex-table-cell flex-1">
            <device-info :device-info="item.deviceInfo" />
          </div>
          <div class="flex-table-cell flex-3">
            <div class="has-text-grey">{{ item.response.msg }}</div>
            <div class="pad-top-5" v-if="item.activationDoc">
              <div>
                <b class="uc">{{ item.activationDoc.type }}:</b>
                {{ item.activationDoc.value }}
              </div>
              <div v-if="item.activationDoc.name" class="has-text-grey font-size-90">
                <b>name:</b> {{ item.activationDoc.name }}
              </div>
            </div>
            <div class="bold has-text-danger pad-top-5" v-if="item.error">{{ item.error }}</div>
          </div>
        </div>

        <div class="center pad-5" v-if="loadingMore || (items && items.length >= limit)">
          <button class="button is-small" :class="{'is-loading': loadingMore}" @click="fetchItems()">Show More</button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import IpAddress from '@/components/IpAddress'
import DeviceInfo from '@/components/DeviceInfo'
export default {
  components: { IpAddress, DeviceInfo },
  data () {
    return {
      ip: null,
      items: null,
      limit: 0,
      loadingMore: false
    }
  },
  computed: {
    itemLimit () { return this.$store.getters.itemLimit }
  },
  created () {
    this.fetchItems()
  },
  methods: {
    fetchItems () {
      this.unsub('activationLogs')
      this.loadingMore = true
      this.limit += this.itemLimit
      this.subs.activationLogs = this.$fs.collection('activationLogs')
        .orderBy('timestamp', 'desc')
        .limit(this.limit)
        .onSnapshot(ss => {
          this.items = this.$ssToArr(ss)
          this.loadingMore = false
        })
    }
  }
}
</script>
