<template>
  <div class="devices height-100">

    <div class="flex-table flex-table-striped flex-table-hoverable flex-table-bordered">

      <div class="toolbar flex">
        <div class="name flex-1">Devices</div>
        <div class="buttons">
          <button class="button" @click="$back()">Back</button>
        </div>
      </div>

      <div class="flex-table-header">
        <div class="flex-table-cell flex-1">App</div>
        <div class="flex-table-cell flex-3">Device Info</div>
        <div class="flex-table-cell flex-2">Activation Type</div>
        <div class="flex-table-cell flex-3">Activations</div>
        <div class="flex-table-cell flex-0 w-70px" />
      </div>
      <div v-if="items" class="flex-table-body">
        <div v-for="item in items" :key="item._id" class="flex-table-row">
          <div class="flex-table-cell flex-1">{{ item.deviceInfo.appName }}</div>
          <div class="flex-table-cell flex-3">
            <device-info :device-info="item.deviceInfo" />
          </div>
          <div class="flex-table-cell flex-2">
            <template v-if="item.activationDoc">
              {{ item.activationDoc.type }}: {{ item.activationDoc.value }}
              <div v-if="item.activationDoc.name" class="bold font-size-90 has-text-grey">{{ item.activationDoc.name }}</div>
            </template>
            <template v-else>Allow All</template>
          </div>
          <div class="flex-table-cell flex-3">
            <div class="flex font-sise-90" v-for="(activation, i) in item.activations" :key="i">
              <div class="flex-0 w-150px bold">
                <ip-address :ip="activation.ip">
                  <div class="flex">
                    <div class="flex-1">{{ activation.ip }}</div>
                    <div>{{ activation.timestamp | formatDateTime }}</div>
                  </div>
                </ip-address>:
              </div>
              <div class="flex-1">{{ activation.timestamp | formatDateTime }}</div>
            </div>
          </div>
          <div class="flex-table-cell flex-0 w-70px center">
            <button class="button is-small" @click="onDelete(item)"><fa icon="times" /></button>
          </div>
        </div>
      </div>

      <div class="center pad-5" v-if="loadingMore || (items && items.length >= limit)">
        <button class="button is-small" :class="{'is-loading': loadingMore}" @click="fetchItems()">Show More</button>
      </div>

    </div>
  </div>

</template>

<script>
import IpAddress from '@/components/IpAddress'
import DeviceInfo from '@/components/DeviceInfo'
import firebase from 'firebase/app'
import 'firebase/firestore'
export default {
  components: { IpAddress, DeviceInfo },
  data () {
    return {
      items: null,
      limit: 0
    }
  },
  computed: {
    itemLimit () { return this.$store.getters.itemLimit }
  },
  created () {
    this.fetchItems()
  },
  methods: {
    fetchItems () {
      this.unsub('devices')
      this.loadingMore = true
      this.limit += this.itemLimit
      this.subs.devices = this.$fs.collection('devices')
        .orderBy('timestamp', 'desc')
        .onSnapshot(ss => {
          this.items = this.$ssToArr(ss)
          this.loadingMore = false
        })
    },
    async onDelete (item) {
      const confirmed = await this.$confirm({
        type: 'is-danger',
        title: 'Removing Device Activation',
        message: `
          Are you fure you want to delete this device activation
          <p class="bold">${item.deviceInfo.deviceName}</p>
          <p class="bold">${item.deviceInfo.deviceUniqueIdentifier}</p>
        </b>`
      })
      if (!confirmed) return
      try {
        const batch = this.$fs.batch()
        const deviceDocRef = this.$fs.doc(`/devices/${item._id}`)
        batch.delete(deviceDocRef)

        const countsDocRef = this.$fs.doc('/auth/counts')
        const countsUpdateData = { total: firebase.firestore.FieldValue.increment(-1) }
        countsUpdateData[item.deviceInfo.appName] = firebase.firestore.FieldValue.increment(-1)
        batch.update(countsDocRef, countsUpdateData)

        if (item.activationDoc && item.activationDoc._id) {
          const activationDocRef = this.$fs.doc(`/activations/${item.activationDoc._id}`)
          if (item.activationDoc.type === 'ip') {
            batch.update(activationDocRef, { count: firebase.firestore.FieldValue.increment(-1) })
          } else if ((item.activationDoc.type === 'code')) {
            batch.delete(activationDocRef)
          }
        }

        await batch.commit()
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
