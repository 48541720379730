<template>
  <div class="file-dropper flex-col height-100">
    <div class="file" :style="{opacity: loading ? '0.5' : '1'}">
      <label class="file-label">
        <input
          class="file-input disabled"
          type="file"
          name="resume"
          :disabled="loading"
          :accept="accept"
          @change="$emit('files', $event.target.files)"
        />
        <span class="file-cta">
          <span class="file-icon">
            <fa icon="upload" />
          </span>
          <span class="file-label">{{ buttonText }}</span>
        </span>
      </label>
    </div>
    <div
      class="dropper margin-top flex-1 flex-middle"
      :class="{dropping: dropping}"
      @drop.prevent="onDropped"
      @dragover.prevent="onDragOver"
      @dragend="onDragEnd"
      @dragleave="onDragEnd"
    >
      <span v-if="!loading">{{ _dropText }}</span>
      <span v-else><fa icon="spinner" pulse /></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: { default: 'Choose a file' },
    accept: { default: '' },
    multiple: { default: false },
    dropText: { default: null },
    hoverText: { default: null },
    loading: { default: false }
  },
  data () {
    return {
      dropping: false
    }
  },
  computed: {
    _dropText () {
      if (!this.dropping) {
        if (this.dropText) return this.dropText
        else return this.multiple ? 'DROP FILES HERE' : 'DROP FILE HERE'
      } else {
        if (this.hoverText) return this.hoverText
        else return this.multiple ? 'DROP FILES' : 'DROP FILE'
      }
    }
  },
  methods: {
    onDragOver (event) {
      if (this.loading) return
      this.dropping = true
      event.dataTransfer.dropEffect = 'copy'
    },
    onDragEnd () {
      this.dropping = false
    },
    onDropped (e) {
      if (this.loading) return
      const files = []
      this.dropping = false
      if (event.dataTransfer.items) {
        for (var i = 0; i < event.dataTransfer.items.length; i++) {
          if (event.dataTransfer.items[i].kind === 'file') {
            var file = event.dataTransfer.items[i].getAsFile()
            files.push(file)
          }
        }
      } else {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          files.push(event.dataTransfer.files[i])
        }
      }
      this.$emit('files', files)
    }
  }
}
</script>

<style>
  .file-dropper .dropper {
    text-align: center;
    font-weight: bold;
    padding: 20px 0;
    border: solid 3px #eee;
    border-radius: 10px;
    color: #ccc;
  }

  .file-dropper .dropper.dropping {
    border-color: #999;
    color: #ccc;
  }
</style>
