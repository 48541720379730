import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import store from '@/store.js'

const firebaseConfig = {
  apiKey: 'AIzaSyA9cPY3rvNhtdLCMMe5OSqlGBT2DKGFniw',
  authDomain: 'adventure-map-admin.firebaseapp.com',
  databaseURL: 'https://adventure-map-admin-default-rtdb.firebaseio.com',
  projectId: 'adventure-map-admin',
  storageBucket: 'adventure-map-admin.appspot.com',
  messagingSenderId: '621361671219',
  appId: '1:621361671219:web:aae561f6eb94f3876cc746',
  measurementId: 'G-7BMVCNPXQH'
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const firestore = firebaseApp.firestore()

firebase.auth()
  .onAuthStateChanged(user => {
    store.commit('user', user || false)
  })

Vue.prototype.$fs = firestore

Vue.prototype.$ssToArr = (snapshot) => {
  if (!snapshot || !snapshot.docs) return false
  return snapshot.docs.map(doc => {
    const data = doc.data()
    data._id = doc.id
    return data
  })
}

Vue.prototype.$ssToObj = (snapshot) => {
  let data = false
  if (snapshot) {
    if (snapshot.docs && snapshot.docs.length) {
      data = snapshot.docs[0].data()
      data._id = snapshot.docs[0].id
    } else if (snapshot.exists) {
      data = snapshot.data()
      data._id = snapshot.id
    }
  }
  return data
}

Vue.prototype.$ssToObjMap = (snapshot) => {
  if (!snapshot || !snapshot.docs) return false
  const obj = {}
  for (const doc of snapshot.docs) {
    const data = doc.data()
    data._id = doc.id
    obj[doc.id] = data
  }
  return obj
}
